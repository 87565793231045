<template>
  <v-container>
    <template>
      <v-row class="justify-center">
        <v-card>
          <v-card-title>
            <span class="headline">Enregistrer une nouvelle réservation sur {{bus.fullName}} </span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-btn :color="!doubleBooking? 'info':'error'" text rounded @click="cloneBookingTwo(); doubleBooking = !doubleBooking"><v-icon left>{{!doubleBooking?'mdi-plus':'mdi-delete'}}</v-icon>{{ !doubleBooking?'Réservation Double':'Annuler Réservation Double'}}</v-btn>
              <template v-if="doubleBooking">
                <v-divider></v-divider>
                <h2>Réservation Nº1</h2></template>
              <v-alert type="error"  v-model="generalError.error">{{generalError.message}}</v-alert>
              <BookingForm :submit-button-disabled="submitButtonDisabled" :depart="depart" :booking="booking" :validation-errors="validationErrors" :general-error="generalError" />
              <template v-if="doubleBooking">
                <v-divider></v-divider>
                <h2>Réservation Nº2</h2>
                <v-divider></v-divider>
                <BookingForm :submit-button-disabled="submitButtonDisabled" :depart="depart" :booking="bookingTwo" :validation-errors="bookingTwoValidationErrors"
                             :general-error="bookingTwoGeneralError" v-if="doubleBooking"/>
              </template>  <v-col sm="12" lg="12" md="12">
                <v-btn
                    ref="submitButton"
                    class="mr-12 success"
                    width="100%"
                    rounded
                    :disabled="submitButtonDisabled"
                    value="Enregistrer" color="primary"
                    filled
                    @click="submitBooking"
                >
                  Enregistrer
                </v-btn>
              </v-col>
               </v-container>
            <small>*champs requis</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>


          </v-card-actions>
        </v-card>

      </v-row>
    </template>
  </v-container>
</template>

<script>
import {clearFormErrors} from "@/form/validation";
import bookingValidationErrorsFields from "@/form/booking-validation-errors-fields";
import BookingForm from "@/views/booking/BookingForm";

export default {
  name: "NewBooking",
  components: {BookingForm},
  props: {
    bus: {
      type: Object,
    },
    depart: {
      type: Object,
      required: true,
    },
    seat: {
      type: Object,
      required: false
    }
  },
  data: () => ({
        valid: true,

        dialog: false,
        formOneValid: false,
        formTwoValid: false,
        submitButtonDisabled: false,
        successfulBooking: false,
        hasMatch: false,
        searchFinished: false,
        foundCustomer: null,
        booking: {
          bus: null,
          depart: null,
          client: null,
          pointDep: null,
          destination: null,
          ticketPaid: false,
          free: false
        },
        bookingTwo: null,
        bookingTwoValidationErrors: null,
        bookingTwoGeneralError: null,
        generalError: {
          error: false,
          message: ""
        },

        validationErrors: bookingValidationErrorsFields,
        doubleBooking: false
      }
  ),
  computed: {

  },
  methods:{
    cloneBookingTwo() {
      let setAll = (obj, val) => Object.keys(obj).forEach(k => obj[k] = val);
      this.bookingTwo = Object.assign({},this.booking)
      setAll(this.bookingTwo,null)
      this.bookingTwoValidationErrors = Object.assign({},this.validationErrors)
      this.bookingTwoGeneralError = Object.assign({},this.generalError)
    },
    submitBooking(){
      clearFormErrors(this.validationErrors,this.generalError)
      if (this.doubleBooking){
      clearFormErrors(this.bookingTwoValidationErrors,this.bookingTwoGeneralError)
        this.bookingTwo.depart = this.booking.depart
        this.bookingTwo.bus = this.booking.bus
      }
      let data =  (this.doubleBooking)? {bookings: [this.booking, this.bookingTwo]}: this.booking;
      let url = this.doubleBooking === true ?'bookings/double':'bookings'
      this.$axios.post(url, data)
          /** @typedef ApiResponseHandler **/
          .then((response) =>{
            this.formDialog = false
            if (response.isSuccessCreated()){
              this.showSuccessAlert("Réservation enregistrée avec succès !")
              this.$refs.bookingForm.reset()
              this.searchFinished = false
              this.hasMatch = false
              this.generalError.error = false
              this.generalError.message = null
              clearFormErrors(this.validationErrors)
              this.$emit('successful-booking', { bus: this.bus, booking: this.booking, message: 'Réservation enregistrée avec succès !' })
              if (typeof this.seat !== "undefined" && this.seat !== null){
                this.seat.booked = true
              }
            }
          }).catch( (error) =>{
        let responseError = error.response
        if (responseError.isUnprocessableEntity(error)){
          responseError.mapValidationErrorsToFields(this.validationErrors, this.generalError)
        }
      })
    },

    validateBookingForm(){
        this.submitBooking(this.booking)
      //todo validate form later
      // if(this.$refs.bookingForm.validate()){
      // }
    },

  },mounted() {
    if (typeof this.bus !== 'undefined'){
      this.booking.bus =  this.bus['@id']
    }
    this.booking.depart =  this.depart['@id']
    if (typeof this.seat !== "undefined" && this.seat !== null){
      this.booking.seat =  this.seat['@id']
    }
  }
}
</script>

<style scoped>

</style>
