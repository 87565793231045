<template>
  <v-form> <v-row>
    <v-col
        cols="12"
        sm="12"
        md="6"
        lg="6"
    >
      <v-text-field
          v-model.number="booking.client"
          @keyup="findCustomer"
          label="Téléphone Client*"

          type="number"
          prepend-icon="mdi-phone"
          :error="validationErrors.client.error"
          :error-messages="validationErrors.client.message"

      ></v-text-field>
      <v-alert v-if="searchFinished" v-model="searchFinished"
               cols="12"
               sm="12"
               md="12"
               lg="12"
               outlined
               rounded
               :color="hasMatch? 'success': 'error'"
               :type="hasMatch? 'success': 'error'"
      >
        <span v-if="hasMatch">{{ foundCustomer.fullName }} {{ foundCustomer.currentBooking != null ? foundCustomer.currentBooking.depart.name : ""}}</span>
        <span v-else>Aucun client trouvé !<v-btn color="info" outlined  >Créer client</v-btn></span>
      </v-alert>
    </v-col>

    <v-col
        cols="12"
        sm="12"
        lg="6"
        md="6"
    >
      <v-autocomplete
          v-model="booking.pointDep"
          item-value="value"
          :items="depart.trajet.pointDeparts.map(function(item) {
                       return {text: item.name, value: item['@id']}
                       })"
          label="Point de depart*"
          required
      ></v-autocomplete>
    </v-col>
    <v-col
        cols="12"
        sm="6">
      <v-autocomplete
          v-model="booking.destination"
          item-value="value"
          :items="depart.trajet.destinations.map(function(item) {
                       return {text: item.name, value: item['@id']}
                       })"
          label="Destinations"
      ></v-autocomplete>
    </v-col>
    <v-col
        cols="12"
        sm="6"
    >
      <v-checkbox v-if="user.canSellTicket"
                  v-model="booking.ticketPaid"
                  label="Ticket acheté?"
      ></v-checkbox>
      <v-radio v-if="isGranted('ROLE_CEO')"
               v-model="booking.free"
               label="Gratuit"
      ></v-radio>
    </v-col>


  </v-row>

  </v-form>

</template>

<script>
import {isValidPhoneNumber} from "@/utils/phone_number_validator";
import ApiResponseHandler from "@/utils/ApiResponseHandler";

export default {
name: "BookingForm",
  props: {
    booking: {
      type: Object,
      required: true
    },
    depart: {
      type: Object,
      required: true
    },
    validationErrors: {
      type: Object,
      required: true
    },
    generalError: {
      type: Object
    }
  },
  data: () => ({
    rules: {
      required: value => !!value || 'Obligatoire.',
      // validPhoneLength:  v => (v.length === 9) || 'Numéro téléphone trop court',
    },
    dialog: false,
    successfulBooking: false,
    hasMatch: false,
    // buttonDisabled: this.submitButtonDisabled,
    searchFinished: false,
    foundCustomer: null
  }),
  methods: {
    findCustomer(){
      this.buttonDisabled = false
      this.searchFinished = false
      this.foundCustomer = null
      this.hasMatch = false
      if ( isValidPhoneNumber(this.booking.client.toString())) {
        this.validationErrors.client.error = false
        this.validationErrors.client.message = null
        let url = "clients/find_by_phone_number/:phoneNumber";
        this.$axios.get(url.replace(":phoneNumber", this.booking.client))
            .then(response => {
              this.searchFinished = true
              if (response.isSuccessful()) {
                this.hasMatch = true
                this.buttonDisabled = false
                this.foundCustomer = response.data
              }
            }).catch(error => {
          this.searchFinished = true
          let response = new ApiResponseHandler(error.response)
          if (response.notFound()){
            this.hasMatch = false
          }
        })
      }else{
        this.validationErrors.client.error = true
        this.validationErrors.client.message = "Téléphone invalide"

      }
    }

  },
}
</script>

<style scoped>

</style>
