<template>
<v-row>
  <v-col>
    <DepartTicketSales :depart="this.depart"/>
  </v-col>
  <v-col>
   <SaveDepartRevenuForm :depart-report="departReport" :chauffeurs="chauffeurs" :employees="employees" :charges="charges"></SaveDepartRevenuForm>
    <v-btn text color="info" @click="addExpenseItem()"><v-icon left>mdi-plus</v-icon>Ajouter une dépense</v-btn>
    <v-btn class="green darken-1" @click="submitData()">{{submitButtonLabel}}</v-btn>
  </v-col>
</v-row>
</template>

<script>
import SaveDepartRevenuForm from "@/views/depart/SaveDepartRevenuForm";
import DepartTicketSales from "@/views/depart/DepartTicketSales";
export default {
name: "SaveDepartRevenu",
  components: {DepartTicketSales, SaveDepartRevenuForm},
  props: {
  depart: { type: Object, required: true}
  },

  data() {
    return {
      departReport:{
        ticketSalesRevenu: null,
        busRentFees: 150000,
        bus: "bus ",
        commentaires: "commentaires",
        depart: this.depart['@id'],
        managedByEmployee:  "/api/employees/1",
        chauffeur:  "/api/chauffeurs/3",
        expenses: [

        ]


      },
      employees:[],
      chauffeurs:[],
      charges:[],
      submitButtonLabel: "Enregistrer"
    }
  },
  methods: {
    addExpenseItem() {
      this.departReport.expenses.push({
        amount: 0,
        justification: null,
        expense: null
      })
    },

    submitData() {
      if (this.depart.departRevenuReport !== null){
        this.updateDepartRevenuReport()
      }else{
        this.createDepartDeport()
      }
    },
    createDepartDeport() {
      this.$axios.post("depart_revenu_reports",this.departReport)
      .then(response => {
        if (response.isSuccessCreated()){
          this.showSuccessAlert("Le bilan a été enregistrer avec succès !", )
          this.depart.departRevenuReport = response.getData()
        }
      })
      .error(error => {
        if (error.response.isUnprocessableEntity()){
          this.showErrorAlert("Les données envoyées ne sont pas valides !")
        }
      })
    },
    updateDepartRevenuReport() {
      this.departReport.chauffeur = this.departReport.chauffeur['@id']
      this.departReport.managedByEmployee = this.departReport.managedByEmployee['@id']
      this.$axios.put("depart_revenu_reports/:id".replace(":id",this.departReport.id), this.departReport)
          .then(response=>{
            if (response.isSuccessful()){
              this.showSuccessAlert("Les modifications du bilan ont été enregistrées avec succès",()=>{

              })
            }
          })
    },
  },
  mounted() {
    if (this.depart.departRevenuReport !== null){
       this.departReport = this.depart.departRevenuReport
      this.submitButtonLabel = "Enregistrer les modifications"
    }
  this.$axios.get("depart_revenu_reports/relations")
      .then(response =>{
        if (response.isSuccessful()){
          let data = response.getData();
          this.employees = data.employees['hydra:member']
          this.chauffeurs = data.chauffeurs['hydra:member']
          this.charges = data.charges['hydra:member']
        }
      })
  }
}
</script>

<style scoped>

</style>
