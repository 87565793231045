<template>

  <v-card class="col-md-6 col-sm-12">
    <v-card-text>
      <v-alert type="error" v-model="generalError.error">{{ generalError.message }}</v-alert>
      <bus-form :depart="depart" :vehicules="vehicules" :bus="bus" @submit="submitNewBus" :validions-fields="validionsFields"/>
    </v-card-text>
  </v-card>
</template>

<script>
import BusForm from "@/views/bus/BusForm";
import ApiResponseHandler from "@/utils/ApiResponseHandler";
export default {
  name: "NewBus",
  components: {BusForm},
  props: {
    depart: {
      type: Object,
      // required: true,
    }
  },
  data() {
    return {
      bus: {
        depart_id: null,
        nom: "Bus " + (this.depart.buses.length +1),
        nombre_place: 57,
        ticket_price: 3550,
        vehicule_id: 1
      },
      vehicules: [],
      generalError: {
          error: false,
          message: null
        },
        validionsFields: {
        nom: {
          error: false,
          message: null
        },
        ticket_price: {
          error: false,
          message: null
        },
        nombre_place: {
          error: false,
          message: null
        },
          vehicle_id: {
          error: false,
          message: null
        },
      }
    }

  },
  methods: {
    submitNewBus(){
      this.bus.name = this.bus.nom.trim()
      this.$axios.post(`departs/${this.depart?.id}/add_bus`, this.bus)
      .then(response => {
        if(response.isSuccessful() ){
          this.showSuccessAlert("Bus crée avec succès")
          this.$store.dispatch("loader/showIsSavedSuccessfully")
          this.depart.buses.push(this.bus)

        }
      }).catch(error => {
        if (error.response instanceof ApiResponseHandler) {
          this.showErrorAlert(error.response.getData().message)

        } else {
          this.showErrorAlert("Une erreur s'est produite")
          console.log(error)
        }

      })

    },
    loadVehicules(){
      this.$axios.get("buses/vehicules")
      .then(response => {
        if(response.isSuccessful()){
          this.vehicules = response.getData()
        }
      }).catch(error => {
        if (error.response instanceof ApiResponseHandler) {
          let response = error.response
          if (response.isUnprocessableEntity()) {
            this.showErrorAlert(response.getData().message)
          }
        } else {
          this.showErrorAlert("Une erreur s'est produite au niveau interne")
        }
      })
    }
  },
  mounted() {
    this.bus.depart_id = this.depart?.id
    this.loadVehicules()
  }
}
</script>

<style scoped>

</style>
