<template>
  <div>
<!--    <v-btn @click="saveToQRCodesToPDF()" outlined rounded color="primary">Enregistrer</v-btn>-->
    <v-row id="qr_codes" class="ma-5">
      <v-col cols="12">
        <h1>Les départs Golob One</h1>
        <h4>Scannez avec votre application Wave ou votre appareil photo pour réserver rapidement</h4>
      </v-col>
      <v-col cols="3"  v-for="(qrCode,index) in qrCodes"
             :key="index">
        <div style="border: red; border-radius: 2px; margin: 2px" class="pa-4 light-blue"><h1 class="pa-2">{{ qrCode.depart }}</h1>
          <img :src="urlToQrCode(qrCode.wave_launch_url)" alt="QR Code"/></div>

      </v-col>

    </v-row>
  </div>
</template>

<script>
import QRious from "qrious"
import JsPDF from "jspdf"
import axios from "axios";
import {API_BASE_URL} from "@/plugins/axios";
export default {
  name: "EventQRCodes",
  data() {
    return {
      departs:[], shop: "1",
      qrCodes:[]
    }
  },
  methods: {
    generateQrCodeForDepart(depart) {
      let qrCode = new QRious({
        value: `https://transport.golobone.net/booking_by_qr_code/departs/${depart.id}/?onsite=true&shop=${this.shop}`,
        size: 200
      })
      return qrCode.toDataURL("img/png")
    },
    urlToQrCode(url) {
      let qrCode = new QRious({
        value: url,
        size: 200
      })
      return qrCode.toDataURL("img/png")
    },
    saveToQRCodesToPDF(){
      let source = document.getElementById('qr_codes');
      console.log(source.innerHTML)

      let jsPdf = new JsPDF()
      jsPdf.fromHtml(source)
      console.log(jsPdf.outputPdf)
      jsPdf.output()

    }
  },
  mounted() {
    // let eventId = this.$route.params.id
    if(typeof this.$route.query.shop != "undefined"){
      this.shop = this.$route.query.shop
    }
    this.$axios.get("departs?trajet=1")
        .then(response => {
          if (response.isSuccessful()) {
            let departs = response.getData();
            this.departs =  departs;

            //multiple requests
            let urls = departs.slice().map(item=>{ return API_BASE_URL +'mobile/payment/wave/get_url/depart/'+item.id})
          axios.all(urls.map(url=>{ return axios.get(url)}))
              .then(axios.spread((...responses)=>{
                    for (let i = 0; i < responses.length; i++) {
                      let response = JSON.parse(responses[i].data)
                      this.qrCodes.push(response)
                    }
                    console.log(this.qrCodes)
              })
              ).catch(error =>{
            console.log(error)
          })
          }

        })
  }
}
</script>

<style scoped>

</style>
