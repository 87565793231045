<template>
  <v-dialog
      v-model="condition"
      centered
      color="success accent-4"
      elevation="25"
      rounded="pill"
      hide-overlay
      @click:outside="hideMessage"
      @close="hideMessage"
  >
      <v-alert type="success">{{message}}</v-alert>

  </v-dialog>
</template>

<script>
export default {
name: "SuccessAlert",
  props:{
  timeout:{},
  condition: { type: Boolean},
  message: null
  },
  methods: {
  hideMessage(){
    this.$store.dispatch("loader/hideIsSavedSuccessfully")
  }
  }
}
</script>

<style scoped>

</style>