<template>

  <div>
    <v-form>
      <v-text-field required type="text" label="Nom du Bus" v-model='bus.name' :error="validionsFields.nom.error"
                    :error-messages="validionsFields.nom.message"/>
      <v-text-field required type="number" label="Nombre de place" v-model.number='bus.nombre_place'
                    :error="validionsFields.nombre_place?.error"
                    :error-messages="validionsFields.nombre_place?.message"/>
      <v-text-field required type="number" label="Prix Ticket" v-model.number='bus.ticket_price'
                    :error="validionsFields.ticket_price?.error"
                    :error-messages="validionsFields.ticket_price?.message"/>
      <v-select required label="Véhicule transport" v-model.number='bus.vehicule_id'
                item-value="id" :items="vehicules" item-text="name">
      </v-select>
      <v-btn  @click="$emit('submit')"  background-color="success" value="Enregistrer" rounded color="success" class="left">Enregistrer</v-btn>
    </v-form>
  </div>
</template>

<script>
export default {
  name: "BusForm",
  props: {
    depart: {
      type: Object,
      // required: true,
    },
    vehicules: {
      type: Array,
      required: true,
    },
    bus: {
      type: Object,
      required: true,
    },
    validionsFields: {
      type: Object
    }
  },
  data() {
    return {
      generalError: {
          error: false,
          message: null
        },
    }

  }
}
</script>

<style scoped>

</style>