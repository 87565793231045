
<style scoped>


  .loginOverlay {
    background:rgba(33,150,243,0.3);
  }
  .photoCredit{
    position: absolute;
    bottom: 15px;
    right: 15px;
  }
</style>
<template>
<v-app>
  <main >
    <v-container  class="loginOverlay">
      <v-row flex align-center justify-center class="loginOverlay" v-if="! shouldChangePassword">
        <v-col xs12 elevation-6 class="col-md-6" offset-md="3">

          <v-toolbar class="pt-5 blue darken-4">
            <v-toolbar-title class="white--text"><h4>Bienvenue dans l'application Caravane Golob One</h4></v-toolbar-title>
          </v-toolbar>
          <v-card class="md-6 xs-12 justify-center" >
              <v-img :src="require('../../assets/logo.png')" width="192" height="192" class="offset-3" />

            <v-card-text >
              <div>
                <v-alert type="error"  text v-if="loginFailed">{{unauthorizedMessage}}</v-alert>
                <v-form v-model="valid" ref="form">
                  <v-text-field
                      label="Nom d'utilisateur"
                      v-model="data.username"
                      required
                  ></v-text-field>
                  <v-text-field
                      label="Mot de passe"
                      v-model="data.password"
                      append-icon='mdi-eye'
                      type='password'
                      :rules="passwordRules"
                      counter
                      required
                  ></v-text-field>
                  <v-col class="col-md-12">
                    <v-btn class="col-12" @click="submit" :class=" { 'blue darken-4 white--text' : valid, disabled: !valid }" rounded color="success">Connexion
                    </v-btn>
                  </v-col>
                </v-form>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-btn @click="$store.dispatch('user_login/logoutUser')">Déconnexion</v-btn>
        <v-card-title>Changer votre mot de passe</v-card-title>
        <ChangePassword />
      </v-row>
    </v-container>
  </main>
</v-app>
</template>
<script>
import * as apiRoutes from '@/constants/api_routes.ts'
import ChangePassword from "@/views/user/ChangePassword";
  export  default {
    name: 'UserLogin',
    components: {ChangePassword},
    data() {
      return {
        valid: false,
        e1: false,
        data:{
          username: '',
          password: ''
        },
        passwordRules: [
          (v) => !!v || 'Mot de passe obligatoire',
        ],
        usernameRules: [
          (v) => !!v || 'E-mail is required',
          (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
        ],
        loginFailed: false,
        unauthorizedMessage: "Nom d'utilisateur ou mot de passe incorrect!",
      }
    },
    methods: {
      submit() {
        if (this.$refs.form.validate()) {
          this.login()
        }
      },
      clear() {
        this.$refs.form.reset()
      },
      login(){
        this.loginFailed = false
        this.$axios.post(apiRoutes.login_path, this.data)
            .then(response => {
                // this.$store.dispatch("user_login/loginUser")
              if (response.isSuccessfulLogin()) {
                this.$store.dispatch('user_login/saveUserCredentials', response.getData())
                if (response.getData().user.shouldChangePassword){
                  this.$store.dispatch("user_login/changePassword", response.getData())
                }
                else{
                  this.$store.dispatch("user_login/loginUser")
                }
              }
            }).catch(error => {
          let response = error.response
          if (response.isUnauthorized()){
            this.loginFailed = true
          }
          if (response.isUnprocessableEntity()) {
            response.mapValidationErrorsToFields(this.validationErrors)
          }
        })
      }
    }
  }

</script>
