<template>
<v-row>
  <v-col>
    <EventExpenseForm :charges="expensesList" :expense="expense" :validation-errors="validationErrors"/>
    <SubmitButton @onclick="submitData()" label="Enregistrer dépense"/>
  </v-col>
</v-row>
</template>

<script>
import SubmitButton from "@/components/SubmitButton";
import EventExpenseForm from "@/views/event/EventExpenseForm";
export default {
name: "SaveEventExpense",
  components: {EventExpenseForm, SubmitButton},
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    expensesList: [],
    expense: {
      amount: 0,
      expense: null,
      event: null
    },
    generalError:{error: false, message: null},
    validationErrors:{
      amount: {error: false, message: null},
      expense: {error: false, message: null},
      event: {error: false, message: null}
    }
  }),
  mounted() {
  this.expense.event = this.event["@id"];
    this.$axios.get("expenses")
        .then(response => {
          if (response.isSuccessful()) {
            this.expensesList = response.getData()
          }

        })
  },
  methods: {
    submitData() {
      this.$axios.post("event_expenses", this.expense)
          .then(response => {
            if (response.isSuccessCreated()) {
              this.showSuccessAlert("Départ enregistré avec succès")
            }
          }).catch(error => {
        let response = error.response
        if (response.isUnprocessableEntity()) {
          response.mapValidationErrorsToFields(this.validationErrors)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
