<template>
<v-row>

  <v-col>
    <v-form>
      <v-text-field v-model.number="departReport.ticketSalesRevenu" label="Encaissé" required></v-text-field>
      <v-text-field v-model.number="departReport.busRentFees" label="Location Bus" required></v-text-field>
      <v-btn class="green darken-1" @click="submitData()">Enregistrer</v-btn>
    </v-form>
  </v-col>
</v-row>
</template>

<script>
export default {
name: "EditDepartRevenu",
  components: {},
  props: {
    departReport: { type: Object, required: true}
  },

  data() {
    return {

      submitButtonLabel: "Enregistrer"
    }
  },
  methods: {

    submitData() {

      this.$axios.put("depart_revenu_reports/:id".replace(":id",this.departReport.id), this.departReport)
          .then(response=>{
            if (response.isSuccessful()){
              this.showSuccessAlert("Les modifications du bilan ont été enregistrées avec succès",()=>{

              })
            }
          }).catch(error=>{
        console.log(error)
        this.showErrorAlert("Une erreur s'est produite !")
      })
    },
  },
  mounted() {
   }
}
</script>

<style scoped>

</style>
