import { render, staticRenderFns } from "./TransferBusBookings.vue?vue&type=template&id=4c3cca45&scoped=true"
import script from "./TransferBusBookings.vue?vue&type=script&lang=js"
export * from "./TransferBusBookings.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c3cca45",
  null
  
)

export default component.exports