<template>

  <div>
    <v-card>
      <v-card-text>
        <v-alert type="error" v-model="generalError.error">{{ generalError.message }}</v-alert>
        <bus-form :depart="depart" :bus="bus" :vehicules="vehicules" @submit="submitChanges"
                  :validions-fields="validionsFields"/>
      </v-card-text>
    </v-card>

  </div>
</template>

<script>
import BusForm from "@/views/bus/BusForm";
export default {
  name: "EditBus",
  components: {BusForm},
  props: {
    depart: {
      type: Object,
      // required: true,
    },
    bus: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      vehicules: [],

      generalError: {
          error: false,
          message: null
        },
        validionsFields: {
        nom: {
          error: false,
          message: null
        },
        ticket_price: {
          error: false,
          message: null
        },
        nombre_place: {
          error: false,
          message: null
        },
      }
    }

  },
  methods: {
    submitChanges(){
      this.$axios.put("buses/:id".replace(":id", this.bus.id), this.bus)
      .then(response => {
        if(response.isSuccessful()){
          this.showSuccessAlert("Modifications enregistrées avec succès !");
        }
      }).catch(error => {
        if (error.response.isUnprocessableEntity()){
          error.response.mapValidationErrorsToFields(this.validionsFields, this.generalError)
        }

      })

    },
    loadVehicules() {
      this.$axios.get("vehicules").then((response) => {
        if (response.isSuccessful()) {
          this.vehicules = response.getData();
        }
      });
    }
  },
  mounted() {
    this.loadVehicules()
  }
}
</script>

<style scoped>

</style>