<template>
  <v-row>
    <v-col>
      <h1>Statistiques</h1>
      <v-list v-if="stats != null" >
        <v-list-item>Réservations: {{ parseInt(stats.bookings).toLocaleString() }}</v-list-item>
        <v-list-item>Tickets: <span >{{ parseInt(stats.tickets).toLocaleString() }}</span></v-list-item>
        <v-list-item>Buses: <span >{{ stats.buses.toLocaleString() }}</span></v-list-item>
        <v-list-item>Départ: <span >{{ stats.departs.toLocaleString() }}</span></v-list-item>
      </v-list>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "StatsPeriod",
  data() {
    return {
      isLoadingData: true,
      stats: null,
      dateStart: "2022-01-01",
      dateEnd: "2023-12-31"
    }
  },
  mounted() {

    this.$axios.get(`events/stats/periode/${this.dateStart}/${this.dateEnd}`)
        .then(response=>{
          if (response.isSuccessful()){
            this.stats = response.getData()


          }
        }).catch(error=> {
      console.log(error)
          this.showErrorAlert("Une erreur s'est produite, voir console ")
    })
  }
}
</script>

<style scoped>

</style>
