<template>
<v-row>
  <v-card>
    <v-card-text>Chiffres départ: {{depart.name}}</v-card-text>
    <v-card-text>

      <TicketSales
          v-model="ticketSales"
          :loading="isLoadingData"
          :ticket-sales="ticketSales">

      </TicketSales>
      <v-row>
        <v-col><h3>total</h3>
          <h1><span  class="text--accent-1">{{ totalTicketSales.toLocaleString() }}</span></h1></v-col></v-row>
    </v-card-text>
  </v-card>
</v-row>
</template>

<script>
import TicketSales from "@/views/ticket_sales/TicketSales";
import {sum_array} from "@/utils/sum_array";
export default {
  name: "DepartTicketSales",
  components: {TicketSales},
  props: {
    depart:{ type:  Object, required: true}
  },
  data: ()=>({
    ticketSales : [],
    isLoadingData: true
  }),
  computed: {
    totalTicketSales() {
      return sum_array("total", this.ticketSales);
    }
  },
  methods:{
    loadTicketSales(){
      this.$axios.get("departs/:id/ticket_sales".replace(":id", this.depart.id))
          .then(response => {
            if (response.isSuccessful()){
              this.ticketSales = response.getData();
            }
          }).finally( ()=>{
        this.isLoadingData = false;
      })
    }
  },
  mounted() {
    this.loadTicketSales()
  }
}
</script>

<style scoped>

</style>
