<template>
  <v-card>
    <v-card-text>
      <v-form>
        <v-text-field type="text" v-model="departReport.bus" label="Bus"></v-text-field>
        <v-select :items="chauffeurs" item-value="@id" item-text="nomComplet" v-model="departReport.chauffeur" label="Chauffeur"></v-select>
        <v-text-field type="number" v-model.number="departReport.ticketSalesRevenu" label="Total Encaissé"></v-text-field>
        <v-text-field type="number" v-model.number="departReport.busRentFees" label="Location Bus"></v-text-field>
        <v-select :items="employees" item-value="@id" item-text="fullName" v-model="departReport.managedByEmployee" label="Géré par"></v-select>
      <v-row v-for="(fee, index) in departReport.expenses" :key="index">
      <DepartFeeForm :fee="fee" :charges="charges">
      </DepartFeeForm><v-btn icon><v-icon color="error" @click="removeExpenseItem(fee)">mdi-delete-circle</v-icon> </v-btn>
    </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import DepartFeeForm from "@/views/depart/DepartFeeForm";
export default {
name: "SaveDepartRevenuForm",
  components: {DepartFeeForm},
  props: {
    departReport: { type: Object, required: true},
    chauffeurs: { type: Array, required: true},
    employees: { type: Array, required: true},
    charges: { type: Array, required: true},

  }, methods:{
    removeExpenseItem(elem) {
      let index = this.departReport.expenses.indexOf(elem);
      if (index > -1) {
        this.departReport.expenses.splice(index, 1);
      }
    }
  }

}
</script>

<style scoped>

</style>
