<template>
<v-row>
  <v-col>
    <h1>Paramètres de l'application</h1>
    <v-list v-if="params != null" >
<!--      loops through params keys and add input to field to modify them-->
      <v-list-item v-for="(value, key) in params" :key="key">
        <v-list-item-title><v-card-text style="font-weight: bold">{{ key.replaceAll('_'," ").toUpperCase()
          }}</v-card-text>
          <v-text-field placeholder="" v-model="params[key]"></v-text-field>
        </v-list-item-title>
      </v-list-item>
    </v-list>
    <v-btn  @click="addNewParam" text color="primary">Ajouter un paramètre</v-btn>
    <v-btn  @click="saveUpdates"  color="primary">Enregistrer</v-btn>

  </v-col>
</v-row>
</template>

<script>
import ApiResponseHandler from "@/utils/ApiResponseHandler";

export default {
name: "AppParams",
  data() {
    return {
      params: {
         "param1": "value1",
        "param2": "value2",
        "param3": "value3",
        "param4": "value4",

      }
    }
  },
  mounted() {
    this.$axios.get(`mobile/app_params`)
        .then(response=>{
          if (response.isSuccessful()){

            this.params = response.getData().data
          }
        }).catch(error=> {
      console.log(error)
          this.showErrorAlert("Une erreur s'est produite, voir console ")
    });
  },
  methods: {
    addNewParam() {
      console.log(this.params)

      let newParam = prompt("Entrez le nom du paramètre");
      if (newParam === null) {
        return;
      }
      const newParamValue = null
      const newParmData = {};
       newParmData[newParam] = newParamValue;
      this.params = {...this.params, ...newParmData}



    },
    saveUpdates() {
      this.$axios.put(`admin/app/update_params`, {data: this.params})
          .then(response=>{
            if (response.isSuccessful()){
              this.showSuccessAlert("Paramètres enregistrés avec succès")
            }
          }).catch(error => {
        if (error.response instanceof ApiResponseHandler) {
          let response = error.response
          if (response.isUnprocessableEntity()) {
            this.showErrorAlert(response.getData().message)
          }
        } else {
          this.showErrorAlert("Une erreur s'est produite au niveau interne")
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
