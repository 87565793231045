<template>
  <div>
    <v-card-text>
      <v-alert v-model="generalError.error" type="error">{{ generalError.message }}</v-alert>
<DepartForm :depart="depart"
            :events="events"
            :trajets="trajets"
            :horaires="horaires"
            :is-loading-data="isLoadingData" :general-error="generalError" :validions-fields="validionsFields"/>
        <v-btn @click="submitChanges" outlined color="success">Enregistrer</v-btn>
    </v-card-text>
  </div>
</template>
<script>
// import axios from "axios";
import {clearFormErrors} from "@/form/validation"
import DepartForm from "@/views/depart/DepartForm";
import ApiResponseHandler from "@/utils/ApiResponseHandler";
export default {
  name: "DepartureEdit",
  components: {DepartForm},
  props: {
    depart: Object
  },
  data(){
    return{
      isLoadingData: true,
      events:[
      ],
      trajets:[
      ],
      horaires:[
      ],

      generalError: {
        error: false,
        message: null
      },
      validionsFields: {
        name: {
          error: false,
          message: null
        },
        date: {
          error: false,
          message: null
        },
        event: {
          error: false,
          message: null
        },horaire: {
          error: false,
          message: null
        },trajet: {
          error: false,
          message: null
        }, visibilite: {
          error: false,
          message: null
        },
      }
    }

  },
  methods:{
    loadEvents(){
      this.isLoadingData = true
      this.$axios.get("departs/data_for_creation")
          .then(response => {
            this.isLoadingData = false

            this.events = response.getData().events
            this.trajets = response.getData().trajets
            this.horaires = response.getData().horaires
          }).catch(() => {
        this.isLoadingData = false
      }).catch(() => {
        this.isLoadingData = false
      })
    },
    submitChanges(){
      clearFormErrors(this.validionsFields)
      let url = "departs/:id".replace(":id", this.depart.id)
      let data = this.depart;
      delete  data.buses;
      this.$axios.put(url, data)
          .then(response => {
            if (response.isSuccessful()) {
              this.showSuccessAlert("Modifications enregistrées avec succès")
            }

          }).catch(error => {
            let response = error.response
        if (response instanceof ApiResponseHandler) {
          if (response.isUnprocessableEntity()) {
            this.showErrorAlert(response.getData().message)
          }
        } else {
          this.showErrorAlert("Une erreur s'est produite lors de l'enregistrement des modifications")
        }
      })

    }
  },
  mounted() {
    this.loadEvents()
  }
}
</script>
