<template>
<v-row>
  <v-data-table
      v-model="ticketSales"
      sortBy="total"
      :headers="headers"
      :items-per-page="70"
      locale="fr-FR"
      :loading="isLoadingData"
      loading-text="Chargement des données..."
      :items="ticketSales">
    <template v-slot:item.total="{ item }">
      {{parseInt(item.total).toLocaleString()}}
    </template>


  </v-data-table>
</v-row>
</template>

<script>
export default {
  name: "TicketSales",
  props: {
    ticketSales: {required: true},
    isLoadingData: Boolean

  },
  data: ()=>({
    headers: [
      {
        text: "Total",
        value: "total"
      },
      {
        text: "Agent",
        value: "soldBy"
      }
    ],
  }),
}
</script>

<style scoped>

</style>