<template>
<v-col>
  <v-card min-width="100%">

      <v-card-text v-show="! isSending" class="grey lighten-3 bo">
        <v-card-title class="text--primary">
         Saisir le message
        </v-card-title>
        <v-textarea label="Message" outlined rounded counter v-model="messageTemplate">
        </v-textarea>
        <v-card-title class="text--primary">
          Choisir les destinataires du message
          {{bookingsFiltered.length}}

        </v-card-title>
        <v-form @submit.prevent="sendScheduleNotifications(messageTemplate)"  ref="filterForm" >
          <v-select v-model="ticketFilterSelected" label="Clients payés ?" clearable placeholder="Préciser le paiement" :items="ticketFilters" item-text="text"
                    item-value="value" outlined rounded dense required @change="filterBookings"></v-select>


          <v-select label="Clients de quel Bus ?" clearable placeholder="Préciser le bus" :items="buses" item-text="name" item-value="name" outlined
                    rounded multiple dense  v-model="selectedBuses" @change="filterBookings">
            <template v-slot:prepend-item>
              <v-list-item
                  ripple
                  @mousedown.prevent
                  @click="toggle"
              >
                <v-list-item-action>
                  <v-icon :color="selectedBuses.length > 0 ? 'indigo darken-4' : ''">
                    {{ icon }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    Tous les bus
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2">

              </v-divider>
            </template>
          </v-select>
          <v-select v-model="selectedPointDeparts" multiple label="Clients de quel point de départs ?"  placeholder="Préciser point dep" :items="pointDeparts" item-text="name"
                    item-value="name" clearable outlined rounded dense @change="filterBookings"></v-select>
          <v-select v-model="selectedDestinations" multiple label="Clients de quelles destinations ?" clearable placeholder="Préciser la destination" :items="destinations" item-text="name"
                    item-value="name" outlined rounded dense required @change="filterBookings"></v-select>

           <v-btn @click="$refs.filterForm.reset()" outlined color="red" rounded v-if="! filterFormIsEmpty">Effacer les filtre</v-btn>
          <v-btn type="submit"  color="primary" elevation="3" shaped rounded append autocapitalize="off"><v-icon>mdi-send</v-icon>
            Envoyer les messages
          </v-btn>
        </v-form>
      </v-card-text>
    <v-card-text>
        <v-row>
          <v-col cols="12" class="col-lg-12 col-sm-12 col-md-12">
           <h3>Liste des clients destinataires du message</h3>
            <v-icon color="red darken-4" @click="shouldShowSelect = !shouldShowSelect">mdi-delete</v-icon>

            <v-data-table
                dense
                v-model="bookingsFiltered"
                :headers="headers"
                :items-per-page="bookingsFiltered.length"
                locale="fr-FR"
                :loading="isLoadingBookings"
                loading-text="Chargement des données..."
                no-data-text="Aucune réservation à afficher"
                no-results-text="Aucun résultat à afficher"
                :items="bookingsFiltered"
                :show-select="shouldShowSelect"
                selectable-key="client"
            >
              <template v-slot:top v-if="isSending">
                <h1>Message en cours d'envoi...: </h1>
                <v-simple-table >
                  <tr>
                    <td><span class="green--text darken-2">{{ sendingProgressMessage }} messages</span></td>
                    <td> <span class="red--text darken-2">{{ failedCountMessage }} messages échoués</span></td>
                    <td>  <v-btn text color="red darken-1"  v-if="isSending" @click="pauseOrResume">
                      <template v-if=" !isPaused">
                        <v-icon>mdi-pause</v-icon>
                        Pause</template>
                      <template v-else>
                        <v-icon >mdi-play</v-icon>
                        Reprendre</template>
                    </v-btn><v-btn text color="red darken-1"  v-if="isSending" @click="stopSending">
                      <v-icon>mdi-stop</v-icon>
                      Arrêter l'envoi
                    </v-btn>
                    </td>
                  </tr>
                </v-simple-table>

                <br>
                <p >{{currentMessage}}
                </p>
              </template>
              <template v-slot:item.smsReceived="{ item, value }">
                <v-icon v-if="value ===true" color="success">mdi-check-circle</v-icon>
                <v-icon color="error" v-else-if="value === false">mdi-alert</v-icon>
                <template v-else>
                <template v-if="item.isSendingSms">
                  <v-progress-circular
                      indeterminate
                      size="20"
                      color="orange darken-4"
                  ></v-progress-circular>
                  </template>
                <v-icon v-else color="primary">mdi-timer-sand</v-icon>
                </template>
              </template>
              <template v-slot:item.seatNumber="{ item, value }">
                <template v-if="item.hasSeat">
                  <v-chip color="success"  v-if="isGranted('ROLE_OWNER')">{{ value }}</v-chip><v-icon v-else>mdi-eye-off</v-icon>
                </template>
              </template>

            </v-data-table>
          </v-col>
          </v-row>
      </v-card-text>

    </v-card>
</v-col>
</template>

<script>
// import axios from "axios";

export default {

name: "SendNotifications",
  props:{
  id: {type: String},
   bus:{
    type: Object
  },
    depart:{
    type: Object
  }
  },
  computed: {
  showSelect(){
    if(this.isSending === false ){
      return this.isSending
    }
    return  this.shouldShowSelect
  },
    hasSelectedAllBuses () {
      return this.selectedBuses.length === this.buses.length
    },
    hasSelectedSomeBuses () {
      return this.selectedBuses.length > 0 && !this.hasSelectedAllBuses
    },
    hasSelectedPointDeparts () {
      return this.selectedPointDeparts.length > 0
    },
    hasSelectedDestinations () {
      return this.selectedDestinations.length > 0
    },hasSelectedBuses () {
      return this.selectedBuses.length > 0
    },
    noBusSelected () {
      return this.selectedBuses.length === 0
    },
    failedCountMessage(){
      return this.bookingsFiltered.filter(booking=>{ return booking.smsReceived === false}).length + "/" + this.bookingsFiltered.length
    },
    icon () {
      if (this.selectedBuses.length === this.buses.length
    ) return 'mdi-close-box'
      if (this.hasSelectedSomeBuses && ! this.hasSelectedAllBuses) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    filterFormIsEmpty(){
      return this.noBusSelected && this.selectedDestinations.length === 0 && this.selectedPointDeparts.length === 0
    }
  },
  data:() =>({
    shouldShowSelect: false,
    firstSendAttemp: true,
    isSending: false,
    bookings: [],
    bookingsFiltered: [],
    buses: [],
    departName:null,
    selectedBuses:[],
    pointDeparts:[],
    selectedPointDeparts:[],
    destinations: [],
    selectedDestinations:[],
    isLoadingBookings: true,
    isPaused: false,
    pausedAtIndex: 0,
    sendingProgressMessage: null,
    ticketFilters : [
        {text:"Tous les clients", value:"all"},
        {text:"Client  payés", value:"paid"},
        {text:"Client  non payés", value:"not_paid"},
    ],
    ticketFilterSelected : null,
    messageTemplate: "Bonjr <Client>\n" +
        "Pour départ <Depart>:\n" +
        "RV <PointDep>, à <Heure>, <Arret>\n" +
        "Numéro agent dans le bus 771163094",
    currentMessage:null,
    headers: [
      {
        text: 'Numéro',
        align: 'start',
        sortable: true,
        value: 'seatNumber',
      },{
        text: 'Client',
        align: 'start',
        sortable: false,
        value: 'client',
      },
      {
        text: 'Téléphone',
        value: 'phoneNumber'
      },
      { text: 'Destina', value: 'destination' },
      { text: 'P. départ', value: 'pointDep' },
      // // { text: 'Inscrit par', value: 'bookedBy' },
      { text: 'Payé par', value: 'ticketSoldBy' },
      { text: 'Actions', value: 'actions' },
    ]

}),

  methods: {
  pauseOrResume(){
    if (this.isPaused){
      this.resume()
    }else {
      this.pause()
    }
  },
  pause(){
    this.isPaused = true
  }, resume(){
    this.isPaused = false
      this.sendScheduleNotifications(this.messageTemplate)
    }, stopSending(){
    this.isSending = false
      this.pause()
      this.bookingsFiltered = []
      this.bookingsFiltered = this.bookings.slice()
      this.pausedAtIndex = 0
    },
    toggle () {
      this.$nextTick(() => {
        if (this.hasSelectedAllBuses) {
          this.selectedBuses = []
        } else {
          this.selectedBuses = this.buses
        }
      })
    },
  filterBookings(){
      let  filterByBus = (booking)=>{
        if ( ! this.hasSelectedBuses){
          return true
        }
        return this.selectedBuses.includes(booking.bus)
      }
      let  filterByPointDepart =(booking)=>{
        if ( ! this.hasSelectedPointDeparts){
          return true
        }
        return this.selectedPointDeparts.includes(booking.pointDep)

      }
      let   filterByDestination = (booking)=> {
        if ( ! this.hasSelectedDestinations){
          return true
        }
        return this.selectedDestinations.includes(booking.destination)
      }
      let   filterByTicket = (booking)=> {
        if ( this.ticketFilterSelected === "paid"){
          return booking.hasTicket === true
        }else if ( this.ticketFilterSelected === "not_paid"){
          return booking.hasTicket === false
        }else{
          return  true
        }
      }
    this.bookingsFiltered = this.bookings.filter(booking=>{

      return filterByBus(booking) && filterByPointDepart(booking) && filterByDestination(booking) && filterByTicket(booking)
    })
  },
    loadBookings() {
      this.$axios.get("departs/" + this.id +"/bookings_for_notification")
          .then((response) => {
            let data = response.getData();
            this.departName = data.depart
            this.buses = data.buses
            this.pointDeparts = data.pointDeparts
            this.destinations = data.destinations
            this.bookings = data.bookings;
            this.bookingsFiltered = data.bookings

          }).finally(()=>{
        this.isLoadingBookings = false

      })
    },
    sendScheduleNotifications(messageTemplate){
      this.isSending = true
      if (this.firstSendAttemp) {
        this.headers.unshift({
          text: 'Reçu',
          align: 'start',
          sortable: true,
          value: 'smsReceived',
        });
        this.firstSendAttemp = false

      }

      let currentIndex = this.pausedAtIndex;
      let  sendMessage = (messageTemplate, booking, axios)=>{

        let mess = messageTemplate
            .replace("<Client>", booking.clientShort)
            .replace("<Depart>", this.departName)
            .replace("<PointDep>", booking.pointDep)
            .replace("<Arret>", booking.rendezVousPoint)
            .replace("<Heure>", booking.schedule)
            .replace("<Wave>", "https://transport.golobone.net/payer/"+booking.id)
            .replace("<OM>", "https://transport.golobone.net/payer/om/"+booking.id)
        this.currentMessage = mess

        return axios.
       request({
         shouldNotShowLoadingIndicator: true,
         method: 'post',
         url:`/bookings/${booking.id}/send_schedule_notification`,
        data: {message: mess}
       })

      }


      let sendNext = (messageTemplate, booking, axios)=>
     {
       if ((currentIndex)+1 <= this.bookingsFiltered.length && typeof booking != "undefined"){
         this.sendingProgressMessage = `${currentIndex + 1}/${this.bookingsFiltered.length}`
         if (!this.isPaused && this.isSending) {
           booking.isSendingSms = true
           this.bookingsFiltered.splice(currentIndex, 1, booking)

           sendMessage(messageTemplate, booking, axios)
               .then(response => {
                 let data = response.data
                 let currentBooking = this.bookingsFiltered[currentIndex];
                 currentBooking.smsReceived = data.sent
                 currentBooking.isSendingSms = false
                 this.bookingsFiltered[currentIndex] = currentBooking
                 this.bookingsFiltered.splice(currentIndex, 1, booking)
                 currentIndex++
                 let nextBooking = this.bookingsFiltered[currentIndex];
                 sendNext(messageTemplate, nextBooking, axios)
               })
         } else {
           this.pausedAtIndex = currentIndex;
         }

       }else{
         this.isSending = false
         this.currentMessage = null
         // resetting pause index to 0 in order for future sending to start from 0
         this.pausedAtIndex = 0
       }

     }

      let booking = this.bookingsFiltered[currentIndex]
      sendNext(messageTemplate, booking, this.$axios)

    }
  },
  mounted() {
    this.loadBookings()
  }
}
</script>

<style scoped>

</style>
