<template>
<v-row>
  <v-card>
    <v-card-text>
      <h2>Les heures de départ {{depart.name}}</h2>
      <v-simple-table>
        <thead>
        </thead>
        <tbody>
        <tr v-for="(busStop, index) in busStopSchedules" :key="index">
          <td>
            <BusStopScheduleForm :bus-stop-schedule="busStop"/>
          </td>
        </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
    <v-card-actions v-if="busStopSchedules.length > 0">
      <v-btn class="blue lighten-1" @click="submitUpdate()">Enregistrer les modifications</v-btn>
      <v-btn class="blue lighten-1" @click="sendSchedulesToCustomers()">Envoyer les heures de départ</v-btn>
    </v-card-actions>
  </v-card>
</v-row>
</template>

<script>
import BusStopScheduleForm from "@/views/depart/BusStopScheduleForm";
export default {
name: "DepartBusStopSchedules",
  components: {BusStopScheduleForm},
  props: {
  depart: {
    required: true,
    type: Object
  }
  },
  data() {
    return {
      busStopSchedules: []
    }
  },
  methods: {
    submitUpdate() {

      this.$axios.request({
        method:"put",
        url: `departs/${this.depart.id}/bus_stop_schedules/update`,
        headers:{ "Accept":"application/json"},
        data: {busStopSchedules: this.busStopSchedules}
      })
      .then(response =>{
        if (response.isSuccessful()){
          this.showSuccessAlert("Les heures ont été modifiées avec succès !", ()=>{

          })
        }
      })
    },
    sendSchedulesToCustomers(){

    },
    getCurrentSchedules(){
      this.$axios.get(`departs/${this.depart.id}/bus_stop_schedules`)
          .then(response =>{
            if (response.isSuccessful()){
              this.busStopSchedules = response.getData();
            }
          })
    }
  },
  mounted() {
    this.getCurrentSchedules();
  }
}
</script>

<style scoped>

</style>
