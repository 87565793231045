<template>
<v-row>
  <v-col>
    <h4>Transférer cette réservation de {{booking.client.fullName}} vers un autre bus</h4>
    <v-select :items="buses" item-value="id" item-text="fullName" label="Transférer vers ?"
              hint="Sélectionner le bus de destination " v-model="target_bus_id"
    :loading="isLoadingBuses">
    </v-select>
    <v-alert type="error" v-if="hasErrors">
      <p>{{ flattenValidationErrors }}</p>
    </v-alert>
    <v-btn outlined color="info" @click="transferBooking()">Transférer</v-btn>
  </v-col>
</v-row>
</template>

<script>
import bookingValidationErrorsFields from "@/form/booking-validation-errors-fields";
import ApiResponseHandler from "@/utils/ApiResponseHandler";
export default {
  name: "TransferBooking",
  props: {
    booking: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      buses: [],
      validationErrors: bookingValidationErrorsFields,
      hasErrors: false,
      flattenValidationErrors: null,
      isLoadingBuses: true,
      target_bus_id : null,
    }
  },
  mounted() {
    this.$axios.get("buses")
        .then(response => {
          if (response.isSuccessful()) {
            this.buses = response.getData()
          }
        }).finally(()=>{
          this.isLoadingBuses = false
    })
  },
  methods: {
    transferBooking() {
      this.$axios.put(`bookings/:id/transfer/target_bus/${this.target_bus_id}`.replace(":id", this.booking.id),{})
          .then(response => {
            if (response.isSuccessful()) {
              this.showSuccessAlert("Réservation transférer avec succès !")
            }
          }).catch(error => {
        if (error.response instanceof ApiResponseHandler) {
          let response = error.response
          if (response.isUnprocessableEntity()) {
            this.showErrorAlert(response.getData().message)
          }
        } else {
          this.showErrorAlert("Une erreur s'est produite au niveau interne")
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
