<template>
<v-row>
  <v-card>
    <v-data-table
        sortBy="total"
        v-model="ticketSales"
        :headers="headers"
        :items-per-page="70"
        locale="fr-FR"
        :loading="isLoadingData"
        loading-text="Chargement des données..."
        :items="ticketSales">

    </v-data-table>
  </v-card>
</v-row>
</template>

<script>
export default {
  name: "BusTicketSales",
  props: {
    bus: Object,
  },
  data: ()=>({
    ticketSales : [],
    headers: [
      {
        text: "Total",
        value: "total"
      },
      {
        text: "Agent",
        value: "soldBy"
      }
    ],
    isLoadingData: true
  }),
  mounted() {
    this.$axios.get("buses/:id/ticket_sales".replace(":id", this.bus.id))
    .then(response => {
      if (response.isSuccessful()){
        this.ticketSales = response.getData();
      }
    }).finally( ()=>{
      this.isLoadingData = false;
    })
  }
}
</script>

<style scoped>

</style>