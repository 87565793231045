<template>

  <v-col v-if="isGranted('ROLE_ADMIN')">
    <v-btn color="info" outlined><router-link :to="{path: ':id/reports'.replace(':id', event.id),id: event.id}">Bilans</router-link></v-btn>
    <v-btn color="info" outlined><router-link :to="{path: ':id/expenses'.replace(':id', event.id),id: event.id}">Dépenses</router-link></v-btn>
    <v-btn color="info" outlined><router-link :to="{path: ':id/departs'.replace(':id', event.id),id: event.id}">Départs</router-link></v-btn>

    <v-btn
        class="mx-2"
        icon
        color="info"
        @click="saveExpense(event)"
    >
      <v-icon dark color="info">
        mdi-wallet
      </v-icon>
    </v-btn>
    <v-btn
        class="mx-2"
        icon
        color="info"
        @click="generateQrCodes(event)"
    >
      <v-icon dark color="info">
        mdi-qrcode
      </v-icon>
    </v-btn>
    <v-btn
        class="mx-2"
        icon
        color="indigo"
        @click="showEventTicketSales(event)"
    >
      <v-icon dark color="indigo">
        mdi-cash-multiple
      </v-icon>
    </v-btn>
    <v-btn
        class="mx-2"
        icon
        color="indigo"
        v-if="this.user.isAdmin"
    >
      <v-icon dark color="teal" @click="openEditEventForm(event)">
        mdi-pencil
      </v-icon>
    </v-btn>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
      v-if="this.user.isAdmin"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
          color="success"
          dark
          v-bind="attrs"
          v-on="on">
        mdi-download
      </v-icon>
    </template>
      <v-card>
        <v-card-text>
          <ExportEventBookings :event="event"></ExportEventBookings>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col>
              <v-btn class="float-right" @click="menu = false" text color="blue">Fermer</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
  </v-menu>

    <v-menu
        :close-on-content-click="false"
        :nudge-width="200"
        offset-x
    >
      <template v-slot:activator="{ on, attrs }">

        <v-btn
            class="mx-2"
            icon
            color="info"
        >
          <v-icon
              color="indigo"
              dark
              v-bind="attrs"
              v-on="on">
            mdi-dots-horizontal
          </v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-action>
              <v-icon  color="purple" @click='saveDepartRevenuReport(event)'>mdi-eye
              </v-icon>
            </v-list-item-action>
            <v-list-item-title> Enregistrer les bilans</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-action>
              <v-icon color="info" @click="showBookingsGrouping(event)">
                mdi-more
              </v-icon>
            </v-list-item-action>
            <v-list-item-title>Voir répartition des clients</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-icon color="indigo" @click="showBusSchedules(event)" >
                mdi-clock
              </v-icon>
            </v-list-item-action>
            <v-list-item-title>Modifier les heures de départ</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-icon color="red" >
                mdi-close-circle
              </v-icon>
            </v-list-item-action>
            <v-list-item-title>Supprimer évenement</v-list-item-title>
          </v-list-item>
        </v-list>


      </v-card>
    </v-menu>
    <v-dialog
        v-model="departActionDialog"
        width="50%"
    >
      <v-card>
        <component :is="departActionComponent" v-bind="departActionComponent" :key="renderKey"/>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="departActionDialog = false"
          >
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-col>
</template>

<script>
import DepartBusStopSchedules from "@/views/depart/DepartBusStopSchedules";
import BookingsGrouping from "@/views/depart/BookingsGrouping";
import SaveEventExpense from "@/views/event/SaveEventExpense";
import EventTicketSales from "@/views/event/EventTicketSales";
import EventEdit from "@/views/event/EventEdit";
import ExportEventBookings from "@/views/event/ExportEventBookings";
export default {
  name: "EventItemActions",
  components: {ExportEventBookings},
  props: {
    event:{
      type: Object,
      required: true
    }
  },
  data: () => ({
    menu: false,
    departActionDialog: false,
    departActionComponent: null,
    renderKey: 0
  }),
  methods: {
    generateQrCodes(event){
      this.$router.push({name: "EVENT_QR_CODES", params:{id: event.id}, query:{shop: 1, fullScreen: true}})
    },
    cancelDeparture (departure){
      alert(departure)
    },
    openNewBusForm() {
      this.$emit("open-new-bus-form")
    },
    openEditEventForm(event) {
      this.renderKey++
      this.departActionDialog = true
      this.departActionComponent = {
        is: EventEdit,
        event: event
      }

    },
    showEventTicketSales(event) {
      this.renderKey++
      this.departActionDialog = true
      this.departActionComponent = {
        is: EventTicketSales,
        event: event
      }
    },
    saveDepartRevenuReport(event) {
      this.$axios.get(`events/${event.id}/save_reports`)
          .then(response=>{
            if (response.isSuccessful()){
              this.showSuccessAlert("Bilans enregistré avec succès")
            }
          }).catch(error=>{
        console.log(error)
            this.showErrorAlert("Une erreur s'est produite",()=>{});
      })
    },
    showBusSchedules(depart) {
      this.renderKey++
      this.departActionDialog = true
      this.departActionComponent = {
        is: DepartBusStopSchedules,
        depart: depart,
      }
    }, showBookingsGrouping(depart) {
      this.renderKey++
      this.departActionDialog = true
      this.departActionComponent = {
        is: BookingsGrouping,
        depart: depart,
      }
    },
    saveExpense(event) {
      this.renderKey++
      this.departActionDialog = true
      this.departActionComponent = {
        is: SaveEventExpense,
        event: event,
      }
    }
  }
}
</script>

<style scoped>

</style>
