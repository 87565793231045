import
    Printer from "pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts";
Printer.vfs = pdfFonts.pdfMake.vfs;
export  default  class BookingsExporter {
    exportBookingsAsPDF (bookings, filename, docTitle) {
        let tableContent = [];
        for (let index in bookings) {
            let booking = bookings[index]
            let row = [booking.seatNumber, booking.client, booking.phoneNumber, booking.pointDep, booking.destination, booking.ticketSoldBy]
            tableContent.push(row);
        }
        let docDefinition = {
            content: [
                {text: docTitle, style: 'header'},
                {
                    style: 'myTable',
                    table: {
                        // headers are automatically repeated if the table spans over multiple pages
                        // you can declare how many rows should be treated as headers
                        headerRows: 0,
                        // widths: ['*', 'auto', 100, '*'],
                        body: tableContent,
                    }
                }
            ],
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 10]
                },
                myTable: {
                    margin: [0, 0, 0, 0]
                },
                subheader: {
                    fontSize: 16,
                    bold: true,
                    margin: [0, 10, 0, 5]
                },
                tableExample: {
                    margin: [0, 5, 0, 15]
                },
                tableHeader: {
                    bold: true,
                    fontSize: 13,
                    color: 'black'
                }
            },
            defaultStyle: {
                // alignment: 'justify'
            }
        };

        Printer.createPdf(docDefinition).download(filename);
    }
    exportBookingsAsText(bookings, filename){
        let FileSaver = require('file-saver');
        let content = "";

        for (let index in bookings)
        {
            let booking = bookings[index]
             content+= booking.seatNumber +"\t"+
                 booking.client +"\t"+
                 booking.phoneNumber +"\t"
                 + booking.pointDep +"\t"
                 + booking.formattedSchedule +"\t"
                 + booking.rendezVousPoint +"\t"
                 + "https://globeone.site/payer/" + booking.id +"\t"
                 + "https://globeone.site/payer/om/" + booking.id +"\t"
                 + booking.destination +"\n"
        }
        let blob = new Blob([content], {type: "text/plain;charset=utf-8"});
        FileSaver.saveAs(blob, "list_:bus_.txt".replace(":bus",filename));
}


}
