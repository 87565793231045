<template>
<v-row>
  <v-card-text>
    <EventForm :event="event" :bus-directions="busDirections"></EventForm>
    <v-btn color="blue" @click="submitData()">Enregistrer</v-btn>
  </v-card-text>
</v-row>
</template>

<script>
import EventForm from "@/views/event/EventForm";
export default {
name: "NewEvent",
  components: {EventForm},
  data: () => ({
    event:{
      name: null,
      dateStart: null,
      dateEnd: null,
      direction: null
    },
    busDirections: [
      {
        name: 'UGB DAKAR',
        id: 1
      },{
        name: 'DAKAR UGB',
        id: 2
      }
    ]
  }),
  methods: {
    submitData() {
      this.$axios.post("events", this.event)
          .then(response => {
            if (response.isSuccessCreated()) {
              this.showSuccessAlert("Évènement crée avec succès !")
            }

          })
    }
  }
}
</script>

<style scoped>

</style>