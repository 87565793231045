<template>
  <v-row justify-lg="true">
    <v-col>
      <h1>Les bilans</h1>
      <v-data-table :headers="headers" :items="departs" :items-per-page="departs.length">
        <template v-slot:item.departName="{items, item, value }">
          {{ value }}
          <li v-for="(bus,index) in item.buses" :key="index">
            {{bus.name}}<br>
            <v-badge :content='bus.numberOfBookings' inline dark color="error"></v-badge>
            <v-badge :content='bus.numberOfTicketsSold' inline dark color="success"></v-badge>

          </li>
        </template>
        <template v-slot:item.profit="{items, item, value }">
          {{ parseInt(value).toLocaleString() }}
        </template>
        <template v-slot:item.ticketSalesRevenu="{items, item, value }">
          {{ parseInt(value).toLocaleString() }}
        </template>
        <template v-slot:item.busRentFees="{items, item, value }">
          {{ parseInt(value).toLocaleString() }}
        </template>
        <template v-slot:item.actions="{item }">
          <v-btn  text>
            <v-icon color="indigo" left @click="updateItem(item)">mdi-pencil</v-icon></v-btn>
        </template>
      </v-data-table>
      <v-col>
        <v-row>
          <h3>Résumé</h3>
          <v-simple-table >
            <template v-slot:default>
              <thead>
              <tr>
                <th>Total Encaissé</th>
                <th>Total Location</th>
                <th>Total Dépense</th>
                <th>Total Bénéfice</th>
              </tr>
              </thead>
              <tbody>
              <td>{{ totalTicketRevenuSales.toLocaleString() }}</td>
              <td>{{ totalBusRentFees.toLocaleString() }}</td>
              <td>{{ totalExpenses.toLocaleString() }}</td>
              <td>{{ totalProfit.toLocaleString() }}</td>
              </tbody>
            </template>
          </v-simple-table>
         </v-row>
      </v-col>
      <v-col>
        <h1>Les dépenses évènement</h1>

        <v-data-table :headers="eventExpensesHeaders" :items="eventExpenses">
          <template v-slot:item.amount="{item, value }">
            {{ parseInt(value).toLocaleString() }}
          </template>
          <template v-slot:item.actions="{item }">
            <v-btn  text><v-icon color="indigo" left @click="updateItem(item)">mdi-pencil</v-icon></v-btn>
          </template>
        </v-data-table>
        <v-col>
          <v-row>
            <h3>{{ 'Total dépenses évènement  : ' }} </h3>
            <h1> {{ eventTotalExpenses.toLocaleString() }}</h1>
          </v-row>
        </v-col>
        <v-col>
          <v-row>
            <v-simple-table >
              <template v-slot:default>
                <thead>
                <tr>
                  <th>Total Bénéfice</th>
                  <th>Total Dépense Évènement</th>
                  <th>Benefice net</th>
                </tr>
                </thead>
                <tbody>
                <td>{{ totalProfit.toLocaleString() }}</td>
                <td>{{ eventTotalExpenses.toLocaleString() }}</td>
                <td><h1>{{ (totalProfit - eventTotalExpenses).toLocaleString() }}</h1></td>
                </tbody>
              </template>
            </v-simple-table>
          </v-row>
        </v-col>
      </v-col>
      <v-dialog
          v-model="departActionDialog"
          width="60%"
          @close="closed()"
      >
        <v-card>
          <component :is="departActionComponent" v-bind="departActionComponent" :key="renderKey" />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="departActionDialog = false"
            >
              Fermer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>

import EditDepartRevenu from "@/views/depart/EditDepartRevenu";

export default {
  name: "RevenuReports",
  data: () => ({
    menu: false,
    departActionDialog: false,
    departActionComponent: null,
    renderKey: 0,
    departs:[],
    headers: [
      {
        text: "Départ",
        value: 'departName'
      },
      {
        text: "Encaissé",
        value: 'ticketSalesRevenu'
      },
      {
        text: "Location",
        value: 'busRentFees'
      },
      {
        text: "Dépense",
        value: 'totalExpenses'
      },
      {
        text: "Bénéfice",
        value: 'profit'
      },{
        text: "Actions",
        value: 'actions'
      }
    ],
    eventExpenses:[],
    eventExpensesHeaders: [
      {
        text: "Dépense",
        value: 'name'
      },
      {
        text: "Montant",
        value: 'amount'
      },
      {
        text: "Justification",
        value: 'comment'
      },
      {
        text: "Fait le",
        value: 'createdAt'
      }
    ],
  }),
  computed:{

    totalProfit: function () {
      let total = 0;
      for (let i = 0; i < this.departs.length; i++) {
        let item = this.departs[i];
        if(item !== null)
        total = total + item.profit
      }
      return total;
    },totalTicketRevenuSales: function () {
      let total = 0;
      for (let i = 0; i < this.departs.length; i++) {
        let item = this.departs[i];
        if(item !== null)
        total = total + item.ticketSalesRevenu
      }
      return total;
    },totalBusRentFees: function () {
      let total = 0;
      for (let i = 0; i < this.departs.length; i++) {
        let item = this.departs[i];
        if(item !== null)
        total = total + item.busRentFees
      }
      return total;
    },totalExpenses: function () {
      let total = 0;
      for (let i = 0; i < this.departs.length; i++) {
        let item = this.departs[i];
        if(item !== null)
        total = total + item.totalExpenses
      }
      return total;
    },eventTotalExpenses: function () {
      let total = 0;
      for (let i = 0; i < this.eventExpenses.length; i++) {
        let item = this.eventExpenses[i];
        if(item !== null)
        total = total + item.amount
      }
      return total;
    }
  },
  mounted() {
    let id = this.$route.params.id
    this.$axios.get("events/:id/revenu_reports".replace(":id", id))
        .then(response => {
          if (response.isSuccessful()) {
            this.departs = response.getData()
          }

        })
    this.$axios.get("events/:id/expenses".replace(":id", id))
        .then(response => {
          if (response.isSuccessful()) {
            this.eventExpenses = response.getData()
          }

        })
  },
  watch: {
    departActionDialog(newValue) {
      if (newValue === false){
        this.departs = this.departs.slice()
      }
    }
  },
  methods: {
    closed(){
      alert("closed")
    },
    updateItem(item) {
      this.renderKey++
      this.departActionDialog = true
      this.departActionComponent = {
        is: EditDepartRevenu,
        departReport: item
      }


    }
  }
}
</script>

<style scoped>

</style>
