<template>
  <div class="text-center">
        <v-list>
          <v-list-item>
            <v-list-item-action>
              <v-icon  color="error"
                       @click='exportBookings(event,"text",false)' >
                mdi-file

              </v-icon>
            </v-list-item-action>
            <v-list-item-title>Exporter Non payés Texte</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-action>
              <v-icon color="success" @click='exportBookings(event,"text",true)'>
                mdi-file
              </v-icon>
            </v-list-item-action>
            <v-list-item-title>Exporter  payés Texte</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-icon color="success"  @click='exportBookings(event,"pdf",true)'>
                mdi-file-pdf-outline              </v-icon>
            </v-list-item-action>
            <v-list-item-title>Exporter  payés PDF</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-icon color="red"  @click='exportBookings(event,"pdf",false)'>
                mdi-file-pdf-outline              </v-icon>
            </v-list-item-action>
            <v-list-item-title>Exporter Non payés PDF</v-list-item-title>
          </v-list-item>
        </v-list>
  </div>

</template>

<script>

export default {
name: "ExportEventBookings",
  props:{
  event: {type: Object, required: true}
  },
  data:()=>({
    menu: false
  }),
  methods:{
    exportBookings(event, format, paye){
      let url = "events/:id/export_bookings";
      this.$axios.get(url.replace(":id",event.id))
          .then(response =>{
            if (response.isSuccessful()){
              let bookings = response.getData();

              if (paye){
                bookings = bookings.filter(booking => booking.hasTicket)
              }else{
                bookings = bookings.filter(booking => ! booking.hasTicket)
              }
              let FileSaver = require('file-saver');
              let content = "";

              for (let index in bookings)
              {
                let booking = bookings[index]
                content+=
                    booking.prenom +" "+booking.nom +"\t"+
                    booking.phoneNumber +"\n"

              }
              let blob = new Blob([content], {type: "text/plain;charset=utf-8"});
              FileSaver.saveAs(blob, "evenement-"+event.name);
              }
          })
    },
  }
}
</script>

<style scoped>

</style>
