<template>
<v-row>
  <v-col>
    <v-alert type="error" v-if="generalError.error">{{ generalError.message }}</v-alert>
    <v-text-field label="Prénom" v-model="employee.firstName" :error="validationErrors.firstName.error"
                  :error-messages="validationErrors.firstName.message"/>
    <v-text-field label="Nom" v-model="employee.lastName" :error="validationErrors.lastName.error"
                  :error-messages="validationErrors.lastName.message"/>
    <v-text-field label="Téléphone" v-model.number="employee.phoneNumber" :error="validationErrors.phoneNumber.error"
                  :error-messages="validationErrors.phoneNumber.message"/>
    <v-text-field label="Poste Occupé" v-model="employee.jobTitle"/>
    <v-text-field label="Description" v-model="employee.description"/>
    <v-text-field label="Catégorie" v-model="employee.category"/>
  </v-col>
</v-row>
</template>

<script>
export default {
name: "EmployeeForm",
  props: {
    employee: {
      type: Object,
      required: true
    },
    generalError: {
      type: Object,
      required: false
    },
    validationErrors: {
      type: Object,
      required: false
    },
  },
}
</script>

<style scoped>

</style>
