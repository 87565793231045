<template>
<v-row>
  <v-col cols="12" sm="12" md="12" lg="12">
    <v-card>
      <v-card-title>Informations personnelles
      <v-card-text>
        <p>{{ customer.fullName }}</p>
        <p>{{ customer.phoneNumber }}</p>
        <p>{{ customer.sexe }}</p>
        <p>{{ customer.createdAt }}</p>
        <v-chip color="success">{{customer.travelCount}}</v-chip>Voyages
        <v-dialog ><template v-slot:activator="{ on, attrs }">
        <v-btn  v-on="on" v-bind="attrs" outlined color="info" ><v-icon left>mdi-pencil</v-icon>Modifier les informations</v-btn>
          </template>
          <v-card>
            <v-card-text>
              <EditCustomer :customer="customer"/>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-card-text>
      </v-card-title>
    </v-card>
  </v-col>
  <v-tabs
      v-model="tab"
      background-color="success accent-4"
      dark>
    <v-tabs-slider></v-tabs-slider>

    <v-tab href="#tab-1">Voyages
      <v-icon>mdi-car</v-icon>
    </v-tab>

    <v-tab href="#tab-2">
      Tickets
      <v-icon>mdi-seat-passenger</v-icon>
    </v-tab>

    <v-tab href="#tab-3">
      Autres
      <v-icon>mdi-account-box</v-icon>
    </v-tab>
  </v-tabs>
  <v-col sm="12" lg="12" md="12">
    <v-tabs-items v-model="tab">
      <v-tab-item active :value="'tab-1'">
        <v-card flat elevation="1">
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-card>
              <v-card-title>Voyage en cours
                <v-card-text>
                  <BookingsDataTable :searchable="false" :sort-desc="[true, false]" sort-field="date" :headers="headers"
                                     :bookings="[currentBooking]" list-title="Voyages"
                                     :is-loading-bookings="isLoading"/>

                </v-card-text>
              </v-card-title>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-card>
              <v-card-title>Les autres voyages passés
                <v-card-text>
                  <BookingsDataTable :sort-desc="[true, false]" sort-field="date" :headers="bookingsHeaders"
                                     :bookings="customer.bookings" list-title="Voyages"
                                     :is-loading-bookings="isLoading"/>
                </v-card-text>
              </v-card-title>
            </v-card>
          </v-col>
        </v-card>
      </v-tab-item>
      <v-tab-item :value="'tab-2'">
        <h1>Les tickets non utilisés</h1>
        <v-data-table :sort-desc="[true, false]" sort-field="date" :headers="ticketHeaders"
                           :items="customer.unusedTickets" list-title="Voyages"
                           :is-loading-bookings="isLoading"/>
      </v-tab-item>
      <v-tab-item :value="'tab-3'">
        <h1>Réservations d'autre client</h1>
      </v-tab-item>
    </v-tabs-items>
  </v-col>

</v-row>
</template>

<script>
import BookingsDataTable from "@/views/booking/BookingsDataTable";
import EditCustomer from "@/views/client/EditCustomer";
export default {
name: "CustomerDetail",
  components: {EditCustomer, BookingsDataTable},
  props: {
  customer: Object,
    currentBooking: Object
  },
  data : ()=>({
  isLoading: false,
    tab: null
  }),
  computed: {
    // listTitle: this.bus.name + this.depart.name,
    headers () {
      return [
        {
          text: 'Date',
          align: 'start',
          sortable: true,
          value: 'date',
        },
        {
          text: 'Numéro',
          value: 'seatNumber',
        },
        { text: 'Destination', value: 'destination.name' },
        { text: 'P. départ', value: 'pointDep.name' },
        // // { text: 'Inscrit par', value: 'bookedBy' },
        // // { text: 'Payé', value: 'ticketSoldBy' },
        { text: 'Actions', value: 'actions' },

      ]
    },
    bookingsHeaders () {
      return [

        {

          text: 'Date',
          align: 'start',
          sortable: true,
          value: 'date',
        },
        { text: 'Depart', value: 'depart.name' },
        { text: 'Bus', value: 'bus.name' },
        {
          text: 'Numéro',
          value: 'seatNumber',
        },
        { text: 'Destination', value: 'destination.name' },
        { text: 'P. départ', value: 'pointDep.name' },
        // // { text: 'Inscrit par', value: 'bookedBy' },
        // // { text: 'Payé', value: 'ticketSoldBy' },
        { text: 'Actions', value: 'actions' },

      ]
    },
    ticketHeaders () {
      return [
        {
          text: 'Date',
          align: 'start',
          sortable: true,
          value: 'soldAt',
        },
        {
          text: 'Prix',
          value: 'price',
        },
        { text: 'Voyage', value: 'soldForTravel' },
        { text: 'P. départ', value: 'pointDep.name' },
        // // { text: 'Inscrit par', value: 'bookedBy' },
        // // { text: 'Payé', value: 'ticketSoldBy' },
        { text: 'Actions', value: 'actions' },

      ]
    },
  },

}
</script>

<style scoped>

</style>
