<template>
<v-row>
  <v-col>
    <h1>Cette partie du site est en cours de développement</h1>
  </v-col>
</v-row>
</template>

<script>
export default {
name: "EmployeeStats"
}
</script>

<style scoped>

</style>
