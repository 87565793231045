<template>
<v-row>
  <v-col>
    <v-data-table :items="cashBacksList" :headers="headers">
      <template v-slot:item.actions="{item}">
        <v-icon color="error" @click="deleteItem(item)">mdi-close-circle</v-icon>

      </template>
    </v-data-table>
  </v-col>
</v-row>
</template>

<script>
import {remove_array_item} from "@/utils/remove_array_item";

export default {
name: "CashBacksList",
  data: () => ({
    cashBacksList:[],
    headers:[
      {
        text: "Montant",
        value:"amount"
      },{
        text: "Commentaires",
        value:"comment"
      },{
        text: "Date",
        value:"createdAt"
      },{
        text: "Actions",
        value:"actions"
      }
    ]
  }),
  mounted() {
  let id = this.$route.params.id
    this.$axios.get("ticket_sale_accounts/:id/cash_backs".replace(":id", id))
        .then(response => {
          if (response.isSuccessful()) {
            this.cashBacksList = response.getData();
          }

        })
  },
  methods: {
    deleteItem(item) {
      let id = item.id;
      this.showAlertConfirm({
        title: "Supprimer cet élément",
        confirmationMessage: "Voulez vous vraiment effectuer cette opération ?",
        okButton: "Confirmer", cancelButton: "Annuler"
      }, () => {
        this.$axios.delete("main_account_cash_backs/:id".replace(":id", id))
            .then(response => {
              if (response.isDeletedSuccessfully()) {
                this.showSuccessAlert("Élément  supprimé avec succès !")
                remove_array_item(item, this.cashBacksList);
              }
            })
      })

    }
  }
}
</script>

<style scoped>

</style>
