<template>
<v-row>
  <v-col>
    <v-data-table :items="events" :headers="headers" no-data-text="Aucun évènement" disable-pagination>
      <template v-slot:item.dateEnd="{ item }">
        {{ (new Date(Date.parse(item.dateEnd))).getDate() +"-" +(new Date(Date.parse(item.dateEnd))).getMonth()+"-" +(new Date(Date.parse(item.dateEnd))).getFullYear() }}
      </template>
      <template v-slot:item.actions="{ item }">
        <EventItemActions :event="item"/>
      </template>
    </v-data-table>
  </v-col>
</v-row>
</template>

<script>
import EventItemActions from "@/views/event/EventItemActions";
export default {
name: "EventsList",
  components: {EventItemActions},
  data: () => ({
    events: [

    ],
    headers: [
      {
        text: "Nom évènement",
        value: "name"
      },{
        text: "Date début",
        value: "dateStart"
      },{
        text: "Date Fin",
        value: "dateEnd"
      },{
        text: "Actions",
        value: "actions"
      }
    ]
  }),
  mounted() {
    this.$axios.get("events")
        .then(response => {
          if (response.isSuccessful()) {
            this.events = response.getData();
          }

        })
  }
}
</script>

<style scoped>

</style>
