import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import DepartureNew from '../views/depart/DepartureNew.vue'
import EmployeeList from "@/views/employee/EmployeeList";
import NewCustomer from "@/views/client/NewCustomer";
import * as constants from "@/constants/routeConstants.ts" ;
import EmployeeStats from "@/views/employee/EmployeeStats";
import EventsList from "@/views/event/EventsList";
import RevenuReports from "@/views/event/RevenuReports";
import DepartsEvent from "@/views/depart/DepartsEvent";
import EventExpensesList from "@/views/event/EventExpensesList";
import CashBacksList from "@/views/employee/CashBacksList";
import Bookings from "@/views/booking/Bookings";
import ChangePassword from "@/views/user/ChangePassword";
import PointDepartsList from "@/views/point_depart/PointDepartsList";
import EventDeparts from "@/views/event/EventDeparts";
import OMPayments from "@/views/ticket_sales/OMPayments";
import SendNotifications from "@/views/depart/SendNotifications";
import EventQRCodes from "@/views/event/EventQRCodes";
import TicketSalesByPaymentMethod from "@/views/ticket_sales/TicketSalesByPaymentMethod";
import Stats from "@/views/event/Stats";
import VehiculesList from "@/views/admin/VehiculesList.vue";
import DiscountsPage from "@/views/ticket_sales/DiscountsPage.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/depart/creer',
    name: 'NewDeparture',
    component: DepartureNew
  },{
    path: '/depart/:id/send_notifications',
    name: 'DepartSendNotification',
    component: SendNotifications,
    props: true
  },{
    path: '/',
    name: 'Home',
    component: Home
  },{
    path: constants.EMPLOYEE_LIST,
    name: 'EmployeeList',
    component: EmployeeList
  },{
    path: constants.EMPLOYEE_STATS,
    name: 'EmployeeStats',
    component: EmployeeStats
  },
  {
    path: '/customers/new',
    name: 'NewCustomer',
    component: NewCustomer
  },
  {
    path: constants.EVENT_LIST,
    name: 'EVENT_LIST',
    component: EventsList
  },{
    path: '/om/transactions',
    name: 'OM_TRANSACTIONS',
    component: OMPayments
  },{
    path: '/finances',
    name: 'Finance',
    component: TicketSalesByPaymentMethod
  }, {
    path: constants.EVENT_CURRENT_DEPARTS,
    name: 'EVENT_DEPART',
    component: DepartsEvent
  },
  {
    path: constants.EVENT_DEPART_REVENU_REPORTS,
    name: 'EVENT_REPORTS',
    component: RevenuReports
  },{
    path: constants.EVENT_EXPENSES,
    name: 'EVENT_EXPENSES',
    component: EventExpensesList
  },{
    path: constants.EVENT_DEPARTS,
    name: 'EVENT_DEPARTS',
    component: EventDeparts
  },{
    path: constants.EVENT_QR_CODES,
    name: 'EVENT_QR_CODES',
    component: EventQRCodes
  },{
    path: "/stats",
    name: 'stats',
    component: Stats
  },{
    path: constants.POINTS_DEPART_LIST,
    name: 'PointDepartList',
    component: PointDepartsList
  },{
    path: constants.EMPLOYEE_CASH_BACKS,
    name: 'CASH_BACKS',
    component: CashBacksList
  },{
    path: constants.BUS_BOOKINGS,
    name: 'BUS_BOOKINGS',
    component: Bookings
  },{
    path: "/change_password",
    name: 'Change-Password',
    component: ChangePassword
  }
  ,{
    path: "/vehicules",
    name: 'ManageVehicules',
    component: VehiculesList
  },{
    path: "/discounts",
    name: 'DiscountsIndex',
    component: DiscountsPage
  },

  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
