import Vue from 'vue'
import Vuex from 'vuex'
import {loader} from './modules/loadingIndicator';
import {user_login} from './modules/user_login';
import {_axios} from '@/plugins/axios'
import ApiResponseHandler from "@/utils/ApiResponseHandler";
import createPersistedState from "vuex-persistedstate";
const actionScope = `loader`;
export function setupInterceptors({ dispatch }) {
    let requestsPending = 0;
    const req = {
        pending: () => {
            requestsPending++;
            dispatch(`${actionScope}/showIsLoadingIndicator`);
        },
        done: () => {
            requestsPending--;
            if (requestsPending <= 0) {
                dispatch(`${actionScope}/hideIsLoadingIndicator`);
            }
        }
    };
    _axios.interceptors.request.use(
        config => {
            if (typeof config.shouldNotShowLoadingIndicator === 'undefined' &&  !config.shouldNotShowLoadingIndicator){
                req.pending();
            }
            if (typeof config.headers != "undefined"){
                config.headers.Authorization = `Bearer ${store.state.user_login.user.token}`
            }else{
            config.headers = {
                'Authorization': `Bearer ${store.state.user_login.user.token}`,
                // "Accept": 'application/json'
            }}
            return config;
        },
        error => {
            requestsPending--;
            req.done();
            return Promise.reject(error);
        }
    );
    _axios.interceptors.response.use(
        response => {
            req.done();
            response = new ApiResponseHandler(response)
            return Promise.resolve(response);
        },
        error => {
            req.done();
            error.response = new ApiResponseHandler(error.response)
            if (error.response.isUnauthorized()){
                let actionModule = 'user_login'
                    dispatch(`${actionModule}/logoutUser`)
                if (typeof error.response.getData().message !== 'undefined' && error.response.getData().message.includes('Expired JWT Token')|| error.response.getData().message.includes('Invalid JWT Token')) {
                    dispatch(`${actionModule}/logoutUser`)
                }
            }else if(error.response.isInternalServerError()) {
                dispatch(`${actionScope}/showErrorOccurred`);

            }
            return Promise.reject(error);
        }
    );
}
Vue.use(Vuex);
export const store = new Vuex.Store({
    plugins:[
        createPersistedState({
            paths: ['user_login.user','user_login.isLoggedIn']
        })
    ],
    state: {
    },
    modules: {
        loader,
        user_login
    }
});


