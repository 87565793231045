<template>
<v-container>
  <v-row>
      <v-card-title>
        Faire un retour vers compte principal
      </v-card-title>
        <v-form>
          <v-text-field label="Montant à retourner"  v-model.number="data.amount"  :error="validationErrors.amount.error" :error-messages="validationErrors.amount.message"></v-text-field>
          <v-text-field label="Commentaires"  v-model.number="data.comment"  :error="validationErrors.comment.error" :error-messages="validationErrors.comment.message"></v-text-field>
          <v-btn rounded color="success" @click="submitData()">Enregistrer</v-btn>
        </v-form>
  </v-row>
</v-container>
</template>

<script>
export default {
name: "RebalanceTicketSaleAccount",
  props: {
    employee: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    data: {
      amount:0,
      comment:'Retour'
    },
    validationErrors: {
      amount: {
        message: null,
        error: false
      }, comment: {
        message: null,
        error: false
      }
    }
  }),
  methods: {
    submitData() {
      this.$axios.put("ticket_sale_accounts/:id/rebalance".replace(":id",this.employee.ticketSaleAccount.id ), this.data)
          .then(response => {
            if (response.isSuccessful()) {
              this.showSuccessAlert("Retour effectué avec succès !")
            }
          }).catch(error => {
        let response = error.response
        if (response.isUnprocessableEntity()) {
          response.mapValidationErrorsToFields(this.validationErrors)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
