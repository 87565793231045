<template>
<form>
  <v-text-field type="text" v-model="event.name">

  </v-text-field>
  <v-text-field type="date" v-model="event.dateStart">

  </v-text-field>
  <v-text-field type="date" v-model="event.dateEnd">

  </v-text-field>
  <v-select item-text="name" item-value="id" :items="busDirections" v-model="event.direction" :loading="isLoadingDirections">

  </v-select>
</form>
</template>

<script>
export default {
  name: "EventForm",
  props:{
    event:{
      type:Object,
      required: true
    },
    busDirections:{
      type:Object,
      required: true
    },
    isLoadingDirections: {
      type: Boolean,
      required: true,
    }
  }
}
</script>

<style scoped>

</style>