<template>
<v-row>
  <p> Modifier les autorisations pour {{employee.fullName}}</p>
  <v-col>
    <v-alert v-if="hasError">{{genericError}}</v-alert>
    <v-switch label="Vendre des tickets" v-model="employee.canSellTicket"></v-switch>
    <v-switch label="Choisir numéro de place" v-model="employee.canChooseSeats"></v-switch>
    <v-switch label="Annuler réservation payée" v-model="employee.canCancelPaidBooking"></v-switch>
  <v-btn @click="saveChanges()" color="success">Enregistrer les modifications</v-btn>
  </v-col>
</v-row>
</template>

<script>
export default {
name: "EmployeePrivileges",
  props: {
    employee: {type: Object, required: true}
  },
  data() {
    return {
      hasError: false,
      genericError: null
    }
  },
  methods: {
    saveChanges() {
      let data = Object.assign({}, this.employee)
      delete  data.firstName
      delete  data.lastName
      delete  data.phoneNumber
      delete data.ticketSaleAccount
      this.$axios.put("employees/:id".replace(":id",this.employee.id), data)
          .then(response => {
            if (response.isSuccessful()) {
              this.showSuccessAlert("Les autorisations sont modifiées avec succès !")
            }
          }).catch(error => {
        let response = error.response
        if (response.isUnprocessableEntity()) {
          this.hasError = true
          this.genericError = response.flattenValidationErrors()
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
