<template>
<v-row>
  <v-col>
    <h4>Note client</h4>
    <v-text-field v-model.number="data.rating" label="Note"></v-text-field>
    <v-text-field v-model.number="data.comment" label="Commentaire"></v-text-field>
    <v-btn rounded class="success" @click="submitRating()">Valider</v-btn>

  </v-col>

</v-row>
</template>

<script>
export default {
name: "Rating",
  props: {
    booking: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    data:{
      rating: null,
      comment: null
    }
  }),
  methods: {
    submitRating() {
      let id = this.booking.id;
      this.$axios.put("bookings/:id".replace(":id", id), this.data)
          .then(response => {
            if (response.isSuccessful()) {
              this.showSuccessAlert("Modifications effectuées avec succès !")
            }
          }).catch(error => {
        let response = error.response
        if (response.isUnprocessableEntity()) {
          this.showErrorAlert(response.flattenValidationErrors);
        }
      })
    }
  },
}
</script>

<style scoped>

</style>
