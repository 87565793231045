<template>
  <v-row>
    <v-col>
      <EmployeeForm :employee="employee" :general-error="generalError" :validation-errors="validationErrors"/>
    <v-btn outlined color="info" @click="submitData()">Enregistrer</v-btn>
    </v-col>
  </v-row>

</template>

<script>
import EmployeeForm from "@/views/employee/EmployeeForm";
import employeeValidationErrorsFields from "@/form/employee-validation-errors-fields";
import {clearFormErrors} from "@/form/validation";

export default {
name: "NewEmployee",
  components: {EmployeeForm},
  data() {
    return {
      employee: {
        "firstName": null,
        "lastName": null,
        "phoneNumber": null,
        "email": "",
        "address": null,
        "jobTitle": null,
        "gender": null,
        "category": null
      },
      generalError: {
        message: null,
        error: false
      },
      validationErrors: employeeValidationErrorsFields
    }
  },
  methods: {
    submitData() {
      clearFormErrors(this.validationErrors)
      this.$axios.post("employees", this.employee)
          .then(response => {
            if (response.isSuccessCreated()) {
              this.showSuccessAlert("Employé crée avec succès")
            }
          }).catch(error =>{
            let response = error.response
        if (response.isUnprocessableEntity()){
          response.mapValidationErrorsToFields(this.validationErrors)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
