let bookingValidationErrorsFields =  {
    client: {
        error: false,
        message: ""
    },
    pointDep: {
        error: false,
        message: ""
    },
    destination: {
        error: false,
        message: ""
    },
    ticketPaid: {
        error: false,
        message: ""
    }
}
export default  bookingValidationErrorsFields;
