let customerValidationErrorsFields =    {
    phoneNumber: {
        error: false,
            message: ""
    },
    prenom: {
        error: false,
            message: ""
    },
    nom: {
        error: false,
            message: ""
    },
    sex: {
        error: false,
            message: ""
    }
}
export default  customerValidationErrorsFields;
