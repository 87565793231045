<template>

  <v-container v-if="isGranted('ROLE_EMPLOYEE')">
    <v-btn
        class="mx-2"
        icon
        color="info"
        @click="openNewBusForm()"
        v-if="user.isAdmin"
    >
      <v-icon dark color="info">
        mdi-car
      </v-icon>
    </v-btn>
    <v-btn
        class="mx-2"
        icon
        color="indigo"
        @click="showTicketDepartSales(depart)"
    >
      <v-icon dark color="indigo">
        mdi-cash-multiple
      </v-icon>
    </v-btn>
    <v-btn
        class="mx-2"
        icon
        color="indigo"
        v-if="user.isAdmin"
    >
      <v-icon dark color="teal" @click="openEditDepartForm(depart)">
        mdi-pencil
      </v-icon>
    </v-btn>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
      v-if="user.isAdmin"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
          color="success"
          dark
          v-bind="attrs"
          v-on="on">
        mdi-download
      </v-icon>
    </template>
      <v-card>
        <v-card-text>
          <ExportDepartBookings :depart="depart"></ExportDepartBookings>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col>
              <v-btn class="float-right" @click="menu = false" text color="blue">Fermer</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
  </v-menu>

    <v-menu
        :close-on-content-click="false"
        :nudge-width="200"
        offset-x
    >
      <template v-slot:activator="{ on, attrs }">

        <v-btn
            class="mx-2"
            icon
            color="info"
        >
          <v-icon
              color="indigo"
              dark
              v-bind="attrs"
              v-on="on">
            mdi-dots-vertical
          </v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-action>
              <v-icon  color="purple" @click='saveDepartRevenuReport(depart)'>mdi-cash-multiple
              </v-icon>
            </v-list-item-action>
            <v-list-item-title v-if="depart.departRevenuReport === null"> Enregistrer le bilan</v-list-item-title>
            <v-list-item-title v-else> Voir le Bilan</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-action>
              <v-icon color="info" @click="showBookingsGrouping(depart)">
                mdi-more
              </v-icon>
            </v-list-item-action>
            <v-list-item-title>Voir répartition des clients</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-icon color="indigo" @click="showBusSchedules(depart)" >
                mdi-clock
              </v-icon>
            </v-list-item-action>
            <v-list-item-title>Gestion des Rendez-Vous</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-icon color="deep-orange" @click="goToScheduleNotifications(depart)" >
                mdi-send
              </v-icon>
            </v-list-item-action>
            <v-list-item-title>Envoi des Rendez-Vous</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-icon color="red" @click="cancelDeparture(depart)" >
                mdi-close-circle
              </v-icon>
            </v-list-item-action>
            <v-list-item-title>Annuler ce départ</v-list-item-title>
          </v-list-item>
        </v-list>


      </v-card>
    </v-menu>
    <v-dialog
        v-model="departActionDialog"
        class="col-md-6 col-lg-6 col-sm-12"
    >
      <v-card>
        <component :is="departActionComponent" v-bind="departActionComponent" :key="renderKey"/>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="departActionDialog = false"
          >
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import DepartureEdit from "@/views/depart/DepartureEdit";
import DepartTicketSales from "@/views/depart/DepartTicketSales";
import ExportDepartBookings from "@/views/depart/ExportDepartBookings";
import SaveDepartRevenu from "@/views/depart/SaveDepartRevenu";
import ShowDepartRevenu from "@/views/depart/ShowDepartRevenu";
import DepartBusStopSchedules from "@/views/depart/DepartBusStopSchedules";
import BookingsGrouping from "@/views/depart/BookingsGrouping";
export default {
  name: "DepartureItemActions",
  components: {ExportDepartBookings},
  props: {
    depart:{
      type: Object,
      required: true
    }
  },
  emits: ["on-depart-canceled"],
  data: () => ({
    menu: false,
    departActionDialog: false,
    departActionComponent: null,
    renderKey: 0
  }),
  methods: {
    cancelDeparture (departure){
      this.showAlertConfirm({
        title: "Annuler  cet départ ?",
        confirmationMessage: "Voulez vous vraiment effectuer cette opération ?",
        okButton: "Confirmer", cancelButton: "Annuler"
      }, () => {
        let id = departure.id;
        this.$axios.delete("departs/:id".replace(":id", id))
            .then(response => {
              if (response.isDeletedSuccessfully()) {
                this.showSuccessAlert("Départ   Annulé avec succès !")
                this.$emit("on-depart-canceled", departure)
              }
            })
      })

    },
    openNewBusForm() {
      this.$emit("open-new-bus-form")
    },
    openEditDepartForm(departure) {
      this.renderKey++
      this.departActionDialog = true
      this.departActionComponent = {
        is: DepartureEdit,
        depart: departure
      }

    },
    showTicketDepartSales(depart) {
      this.renderKey++
      this.departActionDialog = true
      this.departActionComponent = {
        is: DepartTicketSales,
        depart: depart
      }
    },
    saveDepartRevenuReport(depart) {
      this.renderKey++
      this.departActionDialog = true
      if (depart.departRevenuReport === null) {
        this.departActionComponent = {
          is: SaveDepartRevenu,
          depart: depart
        }
      }else {
        this.departActionComponent = {
          is: ShowDepartRevenu,
          depart: depart,
          departRevenuReport: depart.departRevenuReport
        }
      }
    },
    goToScheduleNotifications() {
      this.$router.push({name:"DepartSendNotification", params: {id: this.depart.id}})

    },

    showBusSchedules(depart) {
      this.renderKey++
      this.departActionDialog = true
      this.departActionComponent = {
        is: DepartBusStopSchedules,
        depart: depart,
      }
    }, showBookingsGrouping(depart) {
      this.renderKey++
      this.departActionDialog = true
      this.departActionComponent = {
        is: BookingsGrouping,
        depart: depart,
      }
    }
  }
}
</script>

<style scoped>

</style>
