<template>
  <v-chip small  :disabled="seat.booked" @click="$emit('on-seat-click')" :color="seat.booked ? 'red' : 'success'"
            :content="seat.number" pill text style="font-weight: bold" ><v-icon color="indigo" size="12px">mdi-seat
    -passenger</v-icon> {{
      seat.number }}
  </v-chip>
</template>

<script>
export default {
name: "SeatNumberRenderer",
  props: {
  seat: {
    type: Object,
    required: true
  }
  }
}
</script>

<style scoped>

</style>