import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import sweet from './plugins/sweetalert';
import toastr from './plugins/toastr';
import axios from './plugins/axios';
import {store} from '@/store'
import {setupInterceptors} from '@/store'
import VueSweetalert2 from 'vue-sweetalert2';
// import VueRouter from "vue-router";
import router from './router/index'
import {mapState} from "vuex";
// import {mapGetters} from "vuex";
Vue.use(VueSweetalert2);
// Vue.use(VueRouter);
Vue.config.productionTip = false
export const eventBus = new Vue();
Vue.mixin({
  computed: {
    ...mapState("loader", ["isLoading","savedSuccessfully","serverErrorOccurred"]),
    ...mapState("user_login",['isLoggedIn','user', "shouldChangePassword"]),
  },
  methods: {
    isGranted (role){
      return typeof role !== 'undefined' && this.user.roles.indexOf(role) >= 0
    },
    showSuccessAlert(message, onYesFunction){
      this.$swal({
        title: 'Réussi',
        text: message,
        type: 'success',
      }).then((result) => {
        if (result.value) {
          if (typeof onYesFunction !== 'undefined') {
            onYesFunction()
          }
        }
      });
    }, showErrorAlert(message){
      this.$swal(
          'Error!',
          message,
          'error'
      )

    },
    showAlertConfirm(props, onYesFunction){
      this.$swal({
        title: props.title,
        text: props.confirmationMessage,
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: "Non",
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: props.okButton
      }).then((result) => {
        if (result.value) {
          onYesFunction()
        }
      });
    }
  }
});
new Vue({
  axios,
  toastr,
  sweet,
  router,
  vuetify,
  store,
  render: h => h(App),
  created() {
    setupInterceptors(store)
  }
}).$mount('#app')

