<template>
  <v-container grid-list-lg>
    <v-layout column wrap>
      <v-layout row wrap>
        <v-flex xs4>
          <v-card-text >{{busStopSchedule.pointDep}}</v-card-text>
        </v-flex>
        <v-flex xs4>
          <v-text-field v-model="busStopSchedule.rendezVousPoint"></v-text-field>
        </v-flex>
        <v-flex xs4>
          {{busStopSchedule.rendezVousSchedule}}
          <v-dialog
              v-model="dialog"
              width="500"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="blue lighten-2"
                  dark
                  icon
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon>mdi-calendar</v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="headline grey lighten-2">
                Privacy Policy
              </v-card-title>

              <v-card-text>
                          <v-time-picker
                              v-model="busStopSchedule.rendezVousSchedule"
                              class="mt-1"
                              format="24hr"
                              scrollable

                          ></v-time-picker>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn
                    text
                    @click="dialog = false"
                >Valider</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

        </v-flex>
      </v-layout>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  props: {
    busStopSchedule: {
      type: Object
    },
  },
name: "BusStopScheduleForm",
  data(){
    return {
      dialog: false
    }
  }
}
</script>

<style scoped>

</style>
