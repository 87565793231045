<template>
  <DepartsDataTable :departures="departs"/>
</template>

<script>
import DepartsDataTable from "@/views/depart/DepartsDataTable";
export default {
  name: "DepartsEvent",
  components: {DepartsDataTable},
  data() {
    return {
      departs: []
    }
  },
  mounted() {
    this.$axios.get("departs/current_events/departs")
        .then(response => {
          if (response.isSuccessful()) {
            this.departs = response.getData();
          }

        })
  }
}
</script>

<style scoped>

</style>
