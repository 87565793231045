<template>
  <v-col>
        <v-text-field v-if="searchable"
            v-model="searchValue"
            append-icon="mdi-magnify"
            label="Rechercher un client"
            single-line
            hide-details
        ></v-text-field>

      <v-data-table
          :sortBy="sortField"
          v-model="bookings"
          :headers="headers"
          :items-per-page="bookings.length"
          locale="fr-FR"
          :loading="isLoadingBookings"
          loading-text="Chargement des données..."
          no-data-text="Aucune réservation à afficher"
          no-results-text="Aucun résultat à afficher"
          :search="searchValue"
          :items="bookings">

        <template v-slot:item.seatNumber="{ item, value }">

          <template v-if="item.hasSeat">
          <v-chip color="success"  v-if="isGranted('ROLE_OWNER') || shouldShowSeatNumbers">{{ value }}</v-chip><v-icon v-else>mdi-eye-off</v-icon>
        </template>
          <template v-else>
            <v-icon small color="info" @click="saveTicketPayment(item)">mdi-cart</v-icon>
            <v-icon small class="ml-3" color="info" @click="launchWaveTicketPayment(item)">wave</v-icon>
            <v-icon small class="ml-3" color="warning" @click="launchOMTicketPayment(item)" >om</v-icon>
          </template>
          <template v-if="item.belongsToGroup">
            <v-icon small color="info" @click="showBookingsOfSameGroup(item.group_id)" >mdi-account-group</v-icon>
          </template>
        </template>
        <template v-slot:item.ticketSoldBy="{ item, value }">
          <template v-if="value === 'appli_mobile'">
            <v-icon small class="ml-3" color="info" >
              mdi-cellphone
            </v-icon>
            <span v-if="item.paymentMethod === 'wave'" >
              Wave
            </span>
            <span v-else-if="item.paymentMethod === 'om'" >
              OM
            </span>
          </template>
          <template v-else>{{value}}</template>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
              v-if="!item.isPassed"
              class="mr-3"
              color="info"
              @click="editBooking(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              color="error"
              class="mr-3"
              v-if="!item.isPassed"
              @click="deleteBooking(item)"
          >
            mdi-close-circle
          </v-icon>
          <v-icon
              v-if="item.isPassed"
              color="yellow darken-4"
              @click="rateBooking(item)">
            mdi-star
          </v-icon>
          <v-icon
              v-if="item.isPassed"
              color="red darken-4"
              @click="markAsMissedTravel(item)">
            mdi-account-minus
          </v-icon>
          <v-icon
              color="success"
              class="mr-3"
              @click="openPostponeForm(item)">
            mdi-share
          </v-icon>
        </template>
      </v-data-table>
    <v-dialog v-model="bookingActionDialog" v-if="bookingActionDialog">
      <v-card>
        <v-card-text>
          <component :is="bookingActionComponent" v-bind="bookingActionComponent" :key="renderKey"/>
        </v-card-text>
        <v-card-actions><v-btn @click="bookingActionDialog = false">Fermer</v-btn></v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
// import axios from "axios";
import TransferBooking from "@/views/booking/TransferBooking";
import Rating from "@/views/booking/Rating";

export default {

  name: "BookingsDataTable",
  props:{
    isLoadingBookings: Boolean,
    headers:{ type: Array, default:()=>
          [
            {
              text: 'Numéro',
              align: 'start',
              sortable: true,
              value: 'seatNumber',
            },{
            text: 'Client',
            align: 'start',
            sortable: false,
            value: 'client.fullName',
          },
            {
              text: 'Téléphone',
              value: 'client.phoneNumber'
            },
            { text: 'Destination', value: 'destination' },
            { text: 'P. départ', value: 'pointDep' },
            // // { text: 'Inscrit par', value: 'bookedBy' },
            { text: 'Actions', value: 'actions' },

          ]
    },
    listTitle: String,
    bookings:Array,
    sortField: null,
    sortDesc: Array,
    searchable: {type: Boolean, default: true, required: false},
    shouldShowSeatNumbers: {type: Boolean, default: true, required: false}
  },
  data() {
    return {
      searchValue: '',
      dialog: null,
      bookingActionDialog: false,
      bookingActionComponent: null,
      renderKey: 0    }
  },
  methods: {
    editBooking(booking){
      alert(booking)

    },
    deleteBooking(booking){
      this.showAlertConfirm({title: "Annuler Réservation?",
        confirmationMessage: "Voulez vous vraiment annuler cette réservation",
        okButton:"Je Confirme", cancelButton: "Non"}, ()=>{
        let url = "bookings/:id".replace(":id", booking.id)
        this.$axios.delete(url )
            .then(response =>{
              if (response.isSuccessful()){
                this.showSuccessAlert("Réservation annulée avec succès !")
                let index = this.bookings.indexOf(booking);
                if (index > -1) {
                  this.bookings.splice(index, 1);
                }
              }
            })
      })
    },
    saveTicketPayment(booking){
      this.showAlertConfirm({title: "Enregistrer Paiement?",
        confirmationMessage: "Voulez vous vraiment effectuer cette opération ?",
        okButton:"Je Confirme", cancelButton: "Non"}, ()=>{
        let url = "bookings/:id/save_ticket_payment".replace(":id", booking.id)
        this.$axios.put(url )
            .then(response =>{
              if (response.isSuccessful()){
                this.showSuccessAlert("Paiement enregistrée succès !")
                booking.hasSeat = response.getData().hasSeat;
                booking.seatNumber = response.getData().seatNumber;
              }
            })
      })
    },  launchWaveTicketPayment(booking){
      this.showAlertConfirm({title: "Lancer le Paiement?",
        confirmationMessage: "Le client va recevoir par SMS un lien pour payer",
        okButton:"Je Confirme", cancelButton: "Non"}, ()=>{
        // let url = "mobile/payment/wave/trigger_payment/booking/:id".replace(":id", booking.id)
        let url = "bookings/:id/trigger_payment_request/wave".replace(":id", booking.id)

        this.$axios.get(url )
            .then(response =>{
              if (response.isSuccessful()){
                this.showSuccessAlert("Paiement initié avec succès !")

              }
            })
      })
    },
    launchOMTicketPayment(booking){
      this.showAlertConfirm({title: "Lancer le Paiement?",
        confirmationMessage: "Le client va recevoir un SMS OrangeMoney pour confirmer",
        okButton:"Je Confirme", cancelButton: "Non"}, ()=>{
        // let url = "mobile/payment/om/init/booking/:id".replace(":id", booking.id)
        let url = "bookings/:id/trigger_payment_request/om".replace(":id", booking.id)
        this.$axios.get(url )
            .then(r=> {
              if (r.statusCode === 200) {
                let data = r.getData()
                let status = data.status
                if (typeof status != "undefined" && status === "INITIATED") {
                  this.showSuccessAlert( "Le paiement Orange Money est lancé.");
                } else if (status === "400") {
                  this.error = true
                  this.showErrorAlert(data.detail)

                }

              } else {
                this.showErrorAlert("Une erreur s'est produite au niveau du serveur, contactez le service client pour signalez")
              }
            })
      })
    },
    openPostponeForm(booking) {
      this.bookingActionDialog = true
      this.renderKey++
      this.bookingActionComponent = {
        is: TransferBooking,
        booking: booking,
      }
    },

    rateBooking(booking) {
      this.bookingActionDialog = true
      this.renderKey++
      this.bookingActionComponent = {
        is: Rating,
        booking: booking,
      }
    },
    markAsMissedTravel(item) {
      this.showAlertConfirm({
        title: "Marquer comme  Absent ?",
        confirmationMessage: "Voulez vous vraiment effectuer cette opération ?",
        okButton: "Confirmer", cancelButton: "Annuler"
      }, () => {
        let id = item.id;
        let data = {missingPassenger: true}
        this.$axios.put("bookings/:id".replace(":id", id), data)
            .then(response => {
              if (response.isSuccessful()) {
                this.showSuccessAlert("Modifications effectuées avec succès !")
              }
            }).catch(error => {
          let response = error.response
          if (response.isUnprocessableEntity()) {
            response.mapValidationErrorsToFields(this.validationErrors)
          }
        })

      })
    },
    showBookingsOfSameGroup(group_id) {

      this.$axios
          .get("bookings/group/:id".replace(":id", group_id))
          .then((response) => {
            if (response.isSuccessful()) {
              this.bookings = response.getData()
              // show bookings in a dialog

            }
          })
    }
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>
