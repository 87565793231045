<template>
  <div>
    <v-alert v-model="generalError.error" type="error">{{generalError.message}}</v-alert>
    <v-form>
      <v-text-field v-if="!isMultiple" required type="text" label="Nom du départ" v-model='depart.name'
                     :error="validionsFields.name.error" :error-messages="validionsFields.name.message" />

      <v-text-field
          v-if="!isMultiple"
          required
          type="time"
          label="Time"
          v-model="timePart"
      />
      <v-text-field  v-if="!isMultiple" required type="date" label="Date" v-model='datePart'

                     :error="validionsFields.date.error"
                     :error-messages="validionsFields.date.message" />
      <v-select required label="Horaire"
                v-model.number='depart.horaire_id' :error="validionsFields.horaire.error"
                :error-messages="validionsFields.horaire.message"
                item-value="id" :items="horaires" item-text="name">
      </v-select>
<!--      <v-select  required label="Visibilité" v-model.number='depart.visibilite' -->
<!--                 :error="validionsFields.visibilite.error" :error-messages="validionsFields.visibilite.message"-->
<!--                :items="[{text: 'Au personnel', value:1},{text: 'Tout le monde', value: 3}]"-->
<!--                item-value="value" item-text="text"></v-select>-->
      <v-select required label="Évènement" v-model='depart.event_id' :loading="isLoadingData"
                :error="validionsFields.event.error"
                :error-messages="validionsFields.event.message"
                item-value="id" :items="events"  item-text="libelle"></v-select>
      <v-select label="Trajet" v-model='depart.trajet_id' required :loading="isLoadingData"
                :error="validionsFields.trajet.error"
                :error-messages="validionsFields.trajet.message"
                item-value="id" :items="trajets" item-text="name">
      </v-select>
    </v-form>
  </div>
</template>
<script>
// import axios from "axios";
export default {
  name: "DepartForm",
  props: {
    depart: {
      type:Object,
      required: true
    },
    generalError: {
      type:Object,
      required: false
    },
    validionsFields: {
      type:Object,
      required: false
    },
    isLoadingData: {
      type:Boolean,
      default: false
    },
    events:Array,
    trajets:Array,
    horaires:Array,
    isMultiple:{
      type:Boolean,
      default: false
    }
  },
  computed: {
    formattedDate: {
      get() {
        return this.depart.date ? this.depart.date.split(" ")[0] : ''; // Formats date to yyyy-MM-dd
      },
      set(value) {
        this.depart.date = value; // Updates the original date with the selected date
      }
    }
  },

  mounted() {
    this.datePart = this.depart.date.split(" ")[0] || null; // Extracts the date part from the combined `depart.date`
    this.timePart = this.depart.date.split(" ")[1] || null; // Extracts the time part from the combined `depart.date`
  },
  watch: {
    // Watches for changes in date and time inputs and updates the combined `depart.date`
    datePart() {
      this.updateDepartDate();
    },
    timePart() {
      this.updateDepartDate();
    }
  },
  methods: {
    updateDepartDate() {
      // Combines date and time into the desired format
      this.depart.date = `${this.datePart} ${this.timePart || '00:00:00'}`;
    }
  },
  data() {
    return {
      timePart: null,
      datePart: null
    }
  }
}
</script>