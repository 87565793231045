<template>
  <v-form>
    <v-select  :items="charges" item-value="@id" item-text="name" v-model="fee.expense" label="Dépense"></v-select>
    <v-text-field type="number" v-model.number="fee.amount" label="Montant"></v-text-field>
    <v-text-field type="text" v-model="fee.justification" label="Justification"></v-text-field>

  </v-form>
</template>

<script>
export default {
name: "DepartFeeForm",
  props: {
    fee: { type: Object, required: true},
    charges: { type: Array, required: true},

  }
}
</script>

<style scoped>

</style>