<template>
  <v-container>
    <v-row>
      <v-col>
        <v-btn color="primary" @click="showDialog = true">Add Discount</v-btn>
      </v-col>
    </v-row>
     <v-card-title class="text-h6">Discounts</v-card-title>
            <v-data-table
                :headers="headers"
                :items="discounts"
                class="elevation-1"
            >
              <template v-slot:item.name="{ item }">
                <span>{{ item.name || 'N/A' }}</span>
              </template>
              <template v-slot:item.date_start="{ item }">
                <span>{{ item.date_start || 'N/A' }}</span>
              </template>
              <template v-slot:item.date_end="{ item }">
                <span>{{ item.date_end || 'N/A' }}</span>
              </template>
              <template v-slot:item.bus_ids="{ item }">
                <span>{{ item.bus_ids }}</span>
              </template>
              <template v-slot:item.depart_ids="{ item }">
                <span>{{ item.depart_ids }}</span>
              </template>
              <template v-slot:item.created_at="{ item }">
                <span>{{ item.created_at || 'N/A' }}</span>
              </template>
              <template v-slot:item.updated_at="{ item }">
                <span>{{ item.updated_at || 'N/A' }}</span>
              </template>
            </v-data-table>



    <v-row>
      <h6>Messages de promotion</h6>
      {{promotionalMessages}}
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "DiscountsPage",

  data() {
    return {
      discounts: [],
      promotionalMessages: [],
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Name', value: 'name' },
        { text: 'Amount', value: 'amount' },
        { text: 'Start Date', value: 'date_start' },
        { text: 'End Date', value: 'date_end' },
        { text: 'Bus IDs', value: 'bus_ids' },
        { text: 'Depart IDs', value: 'depart_ids' },
        { text: 'Condition', value: 'condition' },
        { text: 'Created At', value: 'created_at' },
        { text: 'Updated At', value: 'updated_at' },
      ],
      data: [
        {
          id: 1,
          name: null,
          amount: 500,
          date_start: null,
          date_end: null,
          bus_ids: "[2900]",
          depart_ids: "[2900]",
          condition: "more_than_1_ticket",
          created_at: null,
          updated_at: null,
        },
      ],
    };
  },
  mounted(){
    this.$axios.get("discounts")
        .then(response => {
          if (response.isSuccessful()) {
            this.discounts = response.getData().discounts;
            this.promotionalMessages = response.getData().promotionalMessages;
          }
        })
  }
};
</script>




<style scoped>
.text-h6 {
  font-weight: bold;
}
</style>
