<template>
  <DepartsDataTable :departures="departs"/>
</template>

<script>
import DepartsDataTable from "@/views/depart/DepartsDataTable";
export default {
  name: "EventDeparts",
  components: {DepartsDataTable},
  data() {
    return {
      departs: []
    }
  },
  mounted() {
    let eventId = this.$route.params.id

    this.$axios.get("events/:id/departs".replace(':id',eventId))
        .then(response => {
          if (response.isSuccessful()) {
            this.departs = response.getData();
          }

        })
  }
}
</script>

<style scoped>

</style>
