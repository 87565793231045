<template>
<v-row>
  <v-col>
    <CustomerForm :customer="customer" :validation-errors="validationErrors"/>
    <v-btn color="success" text outlined @click="submitChanges()">Enregistrer les modifications</v-btn>
  </v-col>
</v-row>
</template>

<script>
import CustomerForm from "@/views/client/CustomerForm";
import customerValidationErrorsFields from "@/form/customer-validation-errors-fields";
export default {
name: "EditCustomer",
  components: {CustomerForm},
  props: {
    customer: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      validationErrors: customerValidationErrorsFields,
      generalError: {
        error: null,
        message: null
      },
      customer_id: null
    }
  },
  mounted() {
    this.$axios.get("customers/:id".replace(":id", this.customer.id))
        .then(response => {
          if (response.isSuccessful()) {
            this.customer = response.getData()
            this.customer_id = response.getData().id
          }

        })
  },
  methods: {
    submitChanges() {
      this.customer.phoneNumber = this.customer.phoneNumber.toString()
      this.$axios.put("customers/:id".replace(":id", this.customer_id), this.customer).
          then(response =>{
            if (response.isSuccessful()){
              this.showSuccessAlert("Modifications enregistrées avec succès !")
            }
      }).catch(error =>{
        let response = error.response;
        if (response.isUnprocessableEntity()){
          response.mapValidationErrorsToFields(this.validationErrors, this.generalError)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
