
<template>
  <v-app id="inspire">

    <v-main>
              <LoadingIndicator :is-loading="isLoading"/>
      <v-dialog v-model="serverErrorOccurred">
        <v-card>
          <v-card-text>
            <v-alert v-if="serverErrorOccurred">Une erreur interne s'est produite au niveau du serveur</v-alert>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-container class="py-8 px-6" fluid v-if="isLoggedIn">
        <template v-if="! fullScreen">
          <AppBarMenu/>
          <AppNavDrawer/>
        </template>
        <v-row>
          <v-col cols="12">
            <v-card>
              <router-view></router-view>
            </v-card>
            </v-col>
        </v-row>


      </v-container>
      <v-container v-else>
        <v-row>
          <v-col cols="12">
            <v-card>
              <UserLogin/>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import LoadingIndicator from "@/components/LoadingIndicator";
/*import AppBarMenu from "@/components/AppBarMenu";
import SuccessAlert from "@/components/SuccessAlert";
import AppNavDrawer from "@/components/AppNavDrawer";*/
// import Login from "@/components/Login";
import UserLogin from "@/views/user/UserLogin";
import AppBarMenu from "@/components/AppBarMenu";
import AppNavDrawer from "@/components/AppNavDrawer";



export default {
  name: 'App',
  components: {
    AppNavDrawer,
    AppBarMenu,
    UserLogin,
    // Login,
    // AppNavDrawer,
    // SuccessAlert,
    LoadingIndicator,
    // AppBarMenu,
    // Home
  },
  data: () => ({
    drawer: true,
    fullScreen: false,
    links: [
    ],
  }),
  mounted() {
    if (typeof this.$route.query.fullScreen != "undefined"){
      this.fullScreen = true
    }
  }
};
</script>
