<template>
  <div>
    <v-card-text>
      <h2>Modifier le point départ {{pointDepart.name}}</h2>
      <v-alert v-model="generalError.error" type="error">{{ generalError.message }}</v-alert>
      <v-form>
        <v-text-field required type="text" label="Nom" v-model='pointDepart.name' :error="validionsFields.name.error" :error-messages="validionsFields.name.message" />
        <v-text-field required type="time" label="Heure Matin" v-model='pointDepart.departureSchedule' :error="validionsFields.departureSchedule.error" :error-messages="validionsFields.departureSchedule.message" />
        <v-text-field required type="time" label="Heure Soir" v-model='pointDepart.departureScheduleEvening' :error="validionsFields.departureScheduleEvening.error" :error-messages="validionsFields.departureScheduleEvening.message" />
        <v-text-field required type="text" label="Arrêt bus" v-model='pointDepart.arretBus' :error="validionsFields.arretBus.error" :error-messages="validionsFields.arretBus.message" />
      <v-btn @click="submitChanges" color="success" shaped rounded large>Enregistrer les modifications </v-btn>
      </v-form>

    </v-card-text>
  </div>
</template>
<script>
// import axios from "axios";
import {clearFormErrors} from "@/form/validation"
export default {
  name: "PointDepartEdit",
  props: {
    pointDepart: Object
  },
  data(){
    return{
      isLoadingEvents: true,
      isLoadingTrajets: true,
      events:[
      ],
      trajets:[
      ],

      generalError: {
        error: false,
        message: null
      },
      validionsFields: {
        name: {
          error: false,
          message: null
        },
        departureSchedule: {
          error: false,
          message: null
        },
        departureScheduleEvening: {
          error: false,
          message: null
        },
        arretBus: {
          error: false,
          message: null
        }
      }
    }

  },
  methods:{
    submitChanges(){
      clearFormErrors(this.validionsFields)
      let url = "point_departs/:id".replace(":id", this.pointDepart.id)
      let data = {
        heurePointDep: this.pointDepart.departureSchedule,
        heurePointDepSoir: this.pointDepart.departureScheduleEvening,
        name: this.pointDepart.name,
        arretBus: this.pointDepart.arretBus
      }
      this.$axios.put(url, data)
          .then(response => {
            if (response.isSuccessful()) {
              this.showSuccessAlert("Modifications enregistrées avec succès")
            }

          }).catch(error => {
            let response = error.response
         if (response.isUnprocessableEntity()){
           response.mapValidationErrorsToFields(this.validionsFields, this.generalError)
         }
      })

    }
  }
}
</script>
