<template>
<v-container>
  <v-row>
    <v-col>
      <v-text-field type="password" label="Ancien mot de passe" v-model="data.oldPassword" :error="validationErrors.oldPassword.error" :error-messages="validationErrors.oldPassword.message"></v-text-field>
      <v-text-field type="password" label="Nouveau mot de passe" v-model="data.newPassword" :error="validationErrors.newPassword.error" :error-messages="validationErrors.newPassword.message"></v-text-field>
      <v-text-field type="password" label="Répéter nouveau mot de passe" v-model="data.newPasswordRepeated" :error="validationErrors.newPasswordRepeated.error" :error-messages="validationErrors.newPasswordRepeated.message"></v-text-field>
    <v-btn color="success" rounded @click="submitChanges()">Enregistrer les modifications</v-btn>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import  {clearFormErrors} from "@/form/validation";

export default {
name: "ChangePassword",
  data: () => ({
    validationErrors:{
      oldPassword: {
        message:null,
        error: false
      },
      newPassword: {
        message:null,
        error: false
      },
      newPasswordRepeated: {
        message:null,
        error: false
      }
    },
    data:{
      oldPassword:null,
      newPassword:null,
      newPasswordRepeated:null,
    }
  }),
  methods: {
    submitChanges() {
      clearFormErrors(this.validationErrors)
      this.$axios.put("users/:id/update_password".replace(":id", this.user.id), this.data)
          .then(response => {
            if (response.isSuccessful()) {
              this.$store.dispatch("user_login/loginUser")
            }
          }).catch(error => {
        let response = error.response
        if (response.isUnprocessableEntity()) {
          response.mapValidationErrorsToFields(this.validationErrors)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
