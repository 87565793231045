let employeeValidationErrorsFields =    {
    phoneNumber: {
        error: false,
            message: ""
    },
    firstName: {
        error: false,
            message: ""
    },
    lastName: {
        error: false,
            message: ""
    },
    gender: {
        error: false,
            message: ""
    },
    address: {
        error: false,
            message: ""
    }
}
export default  employeeValidationErrorsFields;
