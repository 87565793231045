<template>
<v-row>
  <v-col>
    <v-card-title>Liste des dépenses sur l'évènement {{$route.params.id}}</v-card-title>
    <v-data-table :items="expenses" :headers="headers" no-data-text="Aucune donnée à afficher">
    <template v-slot:item.actions="{item}">
      <v-icon color="error" @click="deleteExpense(item)">mdi-delete</v-icon>
    </template>
    </v-data-table>
  <h3>Total Dépense</h3><h1>{{totalExpenses.toLocaleString()}}</h1>
  </v-col>
</v-row>
</template>

<script>
import {remove_array_item} from "@/utils/remove_array_item";
import {sum_array} from "@/utils/sum_array";

export default {
name: "EventExpensesList",
  data: () => ({
    expenses: [],
    headers:[
      {text:"Dépense", value:"name"},
      {text:"Montant", value:"amount"},
      {text:"Justification", value:"comment"},
      {text:"Date", value:"createdAt"},
      {text:"Actions", value:"actions"},
    ]
  }),
  computed: {
    totalExpenses() {
     return sum_array("amount", this.expenses)
    }
  },
  mounted() {
  let id = this.$route.params.id
    this.$axios.get("events/:id/expenses".replace(":id", id))
        .then(response => {
          if (response.isSuccessful()) {
            this.expenses = response.getData();

          }

        })
  },
  methods: {
    deleteExpense(item) {
      this.showAlertConfirm({title: "Supprimer cet élément",
        confirmationMessage: "Voulez vous vraiment effectuer cette opération ?",
        okButton:"Confirmer", cancelButton: "Annuler"}, ()=>{
        let id = item.id;
        this.$axios.delete("event_expenses/:id".replace(":id", id))
            .then(response => {
              if (response.isDeletedSuccessfully()) {
                this.showSuccessAlert("Élément  supprimé avec succès !")
                remove_array_item(item, this.expenses)
              }
            })
      })

    }
  }
}
</script>

<style scoped>

</style>
