<template>
<v-row>
  <v-col cols="12">
    <v-card>
      <v-card-title></v-card-title>
      <v-card-text>
        <v-row>
          <v-col class="col-md-12 col-sm-12">
            <v-simple-table>
              <thead>
                <tr>
                  <th>Encaissé</th>
                  <th>Location Bus</th>
                  <th>Dépenses</th>
                  <th>Bénéfice</th>
                  <th>Géré par</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ departRevenuReport.ticketSalesRevenu.toLocaleString() }}</td>
                  <td>{{ departRevenuReport.busRentFees.toLocaleString() }}</td>
                  <td>{{ departRevenuReport.totalExpenses.toLocaleString() }}</td>
                  <td>{{ departRevenuReport.profit.toLocaleString() }}</td>
                  <td>{{ departRevenuReport.managedByEmployee.prenom }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-md-12 col-sm-12">
            <h2>Les dépenses</h2>
            <v-data-table hide-default-footer no-data-text="Aucune dépense" :items="departRevenuReport.expenses" :headers="expensesTableHeaders">
              <template v-slot:item.actions="{ item }">
                <v-icon
                    color="error"
                    class="mr-4"
                    @click="deleteExpense(item)"
                >
                  mdi-close-circle
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-btn v-if="!updateFormVisible" @click="showUpdateForm" >Modifier</v-btn>
        <v-row v-if="updateFormVisible">
          <v-col class="col-sm-12 col-md-12">
            <SaveDepartRevenu :depart="depart"></SaveDepartRevenu>
            <v-divider></v-divider>
            <v-btn class="red darken-1" @click="updateFormVisible = false">Annuler</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-col>
</v-row>
</template>

<script>
import SaveDepartRevenu from "@/views/depart/SaveDepartRevenu";
export default {
name: "ShowDepartRevenu",
  components: {SaveDepartRevenu},
  props: {
  departRevenuReport: { type: Object, required: true},
  depart: { type: Object, required: true}
  },

  data() {
    return {
      updateFormVisible: false,
      expensesTableHeaders: [
        {
          text: "Dépense",
          value: "expenses"
        }, {
          text: "Montant",
          value: "amount"
        }, {
          text: "Justification",
          value: "justification"
        }, {
          text: "Actions",
          value: "actions"
        },
      ]
    }
  },
  methods: {
    showUpdateForm() {
      this.updateFormVisible = true
    },


    deleteExpense(item) {
      this.showAlertConfirm({title: "Supprimer dépense?",
        confirmationMessage: "Voulez vous vraiment supprimer cette dépense",
        okButton:"Je Confirme", cancelButton: "Non"}, ()=>{
        let url = "depart_expenses/:id".replace(":id", item.id)
        this.$axios.delete(url )
            .then(response =>{
              if (response.isSuccessful()){
                this.showSuccessAlert("Dépense supprimée avec succès !", ()=>{
                  let index = this.depart.departRevenuReport.expenses.indexOf(item);
                  if (index > -1) {
                    this.depart.departRevenuReport.expenses.splice(index, 1);
                  }
                })
              }
            })
      })
    }
  }
}
</script>

<style scoped>

</style>
