import { render, staticRenderFns } from "./BusForm.vue?vue&type=template&id=1f672410&scoped=true"
import script from "./BusForm.vue?vue&type=script&lang=js"
export * from "./BusForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f672410",
  null
  
)

export default component.exports