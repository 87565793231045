<template>
<v-btn rounded color="success" @click="$emit('onclick')">{{label}}</v-btn>
</template>

<script>
export default {
  name: "SubmitButton",
  props: {
    label: {type: String, default: 'Enregistrer', required: false}
  }
}
</script>

<style scoped>

</style>
