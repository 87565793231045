<template>
  <div>
    <v-dialog
        v-model="isLoading"
        hide-overlay
        persistent
        width="10%"
    >

          <v-progress-circular
              indeterminate
              color="indigo"
          ></v-progress-circular>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "LoadingIndicator"
};
</script>
<style lang="scss">
.loader {
  background: rgba(255, 255, 255, 0.8);
  transition: 0.15s ease-in opacity;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  opacity: 0;
  &--visible {
    opacity: 1;
    pointer-events: all;
  }
}
</style>
