<template>
  <v-row>
    <v-col
        cols="12"
        sm="6"
        md="4"
    >
      <v-text-field
          v-model="customer.prenom"
          label="Prénom*"
          hint="Saisir correctement le nom, sans chiffre ni point"
          :error="validationErrors.prenom.error"
          :error-messages="validationErrors.prenom.message"
      ></v-text-field>

    </v-col>
    <v-col
        cols="12"
        sm="6"
        md="4"
    >
      <v-text-field
          v-model="customer.nom"
          label="Nom*"
          hint="tout en majuscule"
          persistent-hint
          required
          :error="validationErrors.nom.error"
          :error-messages="validationErrors.nom.message"
      ></v-text-field>
    </v-col>
    <v-col
        cols="12"
        sm="6"
        md="4"
    >
      <v-text-field
          v-model="customer.phoneNumber"
          label="Téléphone Client*"
          required
          :error="validationErrors.phoneNumber.error"
          :error-messages="validationErrors.phoneNumber.message"
      ></v-text-field>
    </v-col>
    <v-col  cols="12"
            sm="6"
            md="4">
      <v-text-field
          label="Email"
      ></v-text-field>
    </v-col>
    <v-col  cols="12"
            sm="6"
            md="6" >
      <v-radio-group
          v-model="customer.sexe"
          label="Sexe*"
          required
          row
      >
        <v-radio value="F" label="Feminin">Féminin</v-radio>
        <v-radio value="M" label="Masculin"> </v-radio>
      </v-radio-group>
    </v-col>

  </v-row>
</template>

<script>
export default {
name: "CustomerForm",
  props : {
  customer: {
    type: Object,
    required: true
  },
    validationErrors:{
    type: Object
    }
  }
}
</script>

<style scoped>

</style>
