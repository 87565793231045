<template>

  <v-row class="text-center">
    <v-col class="mb-12">
      <v-btn  color="primary" rounded  @click="generateQrCodes">Afficher les QR Code</v-btn>
        <v-card flat elevation="1">
          <v-card-title>Liste des départs </v-card-title>
          <v-card-text>
            <DepartsDataTable :departures="departures"/>
          </v-card-text>
        </v-card>


    </v-col>
  </v-row>

</template>

<script>

import DepartsDataTable from "@/views/depart/DepartsDataTable";
// import * as userRoles from "@/constants/userRoles.ts"
export default {
  name: 'Home',
  components: {
    DepartsDataTable
   },
  data: () => ({
    departures: [],

  }),
  methods:{
    generateQrCodes(){
      let event_id = 1
      const  route = this.$router.resolve({name: "EVENT_QR_CODES", params:{id: event_id}, query:{shop: 1, fullScreen: true}})
      window.open(route.href, '_blank');

    },
    loadDepartures(){
      this.$axios.get("departs").then((response)=>{
        this.departures = response.getData().data
      })
    },

  },
  mounted() {
    this.loadDepartures()
    this.$root.$on("depart_created", (payload) => {
      this.departures.unshift(payload)

    })

  }

}
</script>
