<template>
  <div>
    <v-card-text>
      <v-alert v-model="generalError.error" type="error">{{ generalError.message }}</v-alert>
<EventForm :event="event" :bus-directions="trajets" :is-loading-directions="isLoadingTrajets" :general-error="generalError" :validions-fields="validionsFields"/>
        <v-btn @click="submitChanges" outlined color="success">Enregistrer</v-btn>
    </v-card-text>
  </div>
</template>
<script>
// import axios from "axios";
import {clearFormErrors} from "@/form/validation"
import EventForm from "@/views/event/EventForm";
export default {
  name: "EventEdit",
  components: {EventForm},
  props: {
    event: Object
  },
  data(){
    return{
      isLoadingEvents: true,
      isLoadingTrajets: true,
      events:[
      ],
      trajets:[
      ],

      generalError: {
        error: false,
        message: null
      },
      validionsFields: {
        name: {
          error: false,
          message: null
        },
        date: {
          error: false,
          message: null
        },
        event: {
          error: false,
          message: null
        },horaire: {
          error: false,
          message: null
        },trajet: {
          error: false,
          message: null
        }
      }
    }

  },
  methods:{
    loadDirections(){
      this.$axios.get("trajets")
          .then(response => {
            this.isLoadingTrajets = false
            this.trajets = response.getData()
          }).catch(() => {
        this.isLoadingTrajets = false
      }).finally(()=>{
        this.isLoadingTrajets = false
      })
    },
    submitChanges(){
      clearFormErrors(this.validionsFields)
      let url = "events/:id".replace(":id", this.event.id)
      let data = this.event;
      this.$axios.put(url, data)
          .then(response => {
            if (response.isSuccessful()) {
              this.showSuccessAlert("Modifications enregistrées avec succès")
            }

          }).catch(error => {
            let response = error.response
         if (response.isUnprocessableEntity()){
           response.mapValidationErrorsToFields(this.validionsFields, this.generalError)
         }
      })

    }
  },
  mounted() {
    this.loadDirections()
  }
}
</script>
