import { render, staticRenderFns } from "./TicketSales.vue?vue&type=template&id=5623e99c&scoped=true"
import script from "./TicketSales.vue?vue&type=script&lang=js"
export * from "./TicketSales.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5623e99c",
  null
  
)

export default component.exports