<template>
  <v-form>
    <v-select :items="charges" item-value="@id" item-text="name" v-model="expense.expense" label="Dépense"></v-select>
    <v-text-field type="number" v-model.number="expense.amount" label="Montant" :error-messages="validationErrors.amount.message" :error="validationErrors.amount.error"></v-text-field>
    <v-text-field type="text" v-model="expense.comment" label="Justification"></v-text-field>

  </v-form>
</template>

<script>
export default {
  name: "EventExpenseForm",
  props: {
    expense: { type: Object, required: true},
    charges: { type: Array, required: true},
    validationErrors: {type: Object, required: false}

  }
}
</script>

<style scoped>

</style>
