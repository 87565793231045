<template>
  <v-container>
    <!-- Table to show vehicle data -->
    <v-data-table
        :headers="headers"
        :items="vehiclesData"
        :search="search"
        class="elevation-1"
        :items-per-page="5"
    >
      <template v-slot:top>
        <v-text-field
            v-model="search"
            label="Search"
            class="mx-4"
            clearable
        ></v-text-field>
      </template>

      <!-- Custom icon rendering for 'default' field -->
      <template v-slot:item.default="{ item }">
        <v-icon v-if="item.default" color="green">mdi-check-circle</v-icon>
        <v-icon v-else color="red">mdi-close-circle</v-icon>
      </template>

      <!-- Action buttons for each row -->
      <template v-slot:item.actions="{ item }">
        <v-btn icon @click="editVehicle(item)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn icon @click="editFeatures(item)">
          <v-icon>mdi-format-list-bulleted</v-icon>
        </v-btn>
        <v-btn icon @click="openAttachmentDialog(item)">
          <v-icon>mdi-paperclip</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <!-- Dialog to edit vehicle details -->
    <v-dialog v-model="editDialog" max-width="600px">
      <v-card>
        <v-card-title>Edit Vehicle</v-card-title>
        <v-card-text>
          <v-form ref="editForm">
            <v-text-field v-model="selectedVehicle.matricule" label="Matricule"></v-text-field>
            <v-text-field v-model="selectedVehicle.chauffeur" label="Chauffeur"></v-text-field>
            <v-text-field v-model="selectedVehicle.nombre_place" label="Nombre de Places" type="number"></v-text-field>
            <v-textarea v-model="selectedVehicle.description" label="Description"></v-textarea>
            <v-text-field v-model="selectedVehicle.name" label="Name"></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="primary" @click="saveVehicle">
            <v-icon left>mdi-content-save</v-icon> Save
          </v-btn>
          <v-btn text color="secondary" @click="editDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog to edit vehicle features -->
    <v-dialog v-model="featuresDialog" max-width="600px">
      <v-card>
        <v-card-title>Edit Vehicle Features</v-card-title>
        <v-card-text>
          <v-form ref="featuresForm">
            <v-list>
              <v-list-item v-for="(feature, index) in selectedVehicle.features" :key="index">
                <v-text-field v-model="selectedVehicle.features[index]" label="Feature"></v-text-field>
                <v-btn icon @click="removeFeature(index)">
                  <v-icon color="red">mdi-minus-circle</v-icon>
                </v-btn>
              </v-list-item>
              <v-btn text @click="addFeature">
                <v-icon left>mdi-plus-circle</v-icon>Add Feature
              </v-btn>
            </v-list>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="primary" @click="saveFeatures">
            <v-icon left>mdi-content-save</v-icon> Save
          </v-btn>
          <v-btn text color="secondary" @click="featuresDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog to upload and preview attachments -->
    <v-dialog v-model="attachmentDialog" max-width="600px">
      <v-card>
        <v-card-title>Upload Attachments</v-card-title>
        <v-card-text>
          <v-file-input
              v-model="newAttachments"
              label="Select Files"
              accept="image/*,video/*"
              multiple
              prepend-icon="mdi-upload"
              show-size
              outlined
          ></v-file-input>

          <v-row>
            <v-col v-for="(file, index) in newAttachments" :key="index" cols="4">
              <v-card class="pa-2">
                <v-img v-if="isImage(file)" :src="URL.createObjectURL(file)" height="100px"></v-img>
                <video v-else height="100px" controls :src="URL.createObjectURL(file)"></video>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="primary" @click="uploadAttachments">
            <v-icon left>mdi-cloud-upload</v-icon>Upload
          </v-btn>
          <v-btn text color="secondary" @click="attachmentDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      search: '',
      newAttachments: [], // Stores multiple files to be uploaded
      editDialog: false, // Controls edit vehicle dialog visibility
      featuresDialog: false, // Controls edit features dialog visibility
      attachmentDialog: false, // Controls attachment upload dialog visibility
      selectedVehicle: {}, // Holds the vehicle being edited
      vehiclesData: [
        {
          id: 1,
          matricule: "SL-2703-C",
          chauffeur: "Ahmed",
          nombre_place: 57,
          vehicule_type: 1,
          description: "Bus standard sans clim",
          name: "Bus non climatisé",
          features: [],
          attachments: [],
          default: false,
        },
        // other vehicle objects
      ],
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Matricule', value: 'matricule' },
        { text: 'Chauffeur', value: 'chauffeur' },
        { text: 'Nombre de Places', value: 'nombre_place' },
        { text: 'Type de Véhicule', value: 'vehicule_type' },
        { text: 'Description', value: 'description' },
        { text: 'Nom', value: 'name' },
        { text: 'Défaut', value: 'default', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  methods: {
    isImage(file) {
      return file.type.startsWith('image');
    },

    // Opens attachment dialog for selected vehicle
    openAttachmentDialog(vehicle) {
      this.selectedVehicle = vehicle;
      this.attachmentDialog = true;
    },

    // Handles multiple file uploads
    uploadAttachments() {
      if (this.newAttachments.length) {
        const formData = new FormData();
        this.newAttachments.forEach((file) => formData.append('files[]', file));
        formData.append('vehicle_id', this.selectedVehicle.id);

        this.$axios.post('/api/attachments/upload', formData).then((response) => {
          this.selectedVehicle.attachments.push(...response.data); // Add new attachments to vehicle
          this.newAttachments = []; // Reset the file input
          this.attachmentDialog = false;
        }).catch((error) => {
          console.error('Upload failed:', error);
        });
      }
    },

    // Open the edit vehicle dialog and populate selected vehicle data
    editVehicle(vehicle) {
      this.selectedVehicle = {...vehicle};
      this.editDialog = true;
    },

    // Save vehicle data after editing
    saveVehicle() {
      this.$axios.put(`/api/vehicles/${this.selectedVehicle.id}`, this.selectedVehicle)
          .then(() => {
            Object.assign(
                this.vehiclesData.find((v) => v.id === this.selectedVehicle.id),
                this.selectedVehicle
            );
            this.editDialog = false;
          }).catch((error) => {
        console.error('Failed to save vehicle:', error);
      });
    },

    // Open the edit features dialog and populate selected vehicle's features
    editFeatures(vehicle) {
      this.selectedVehicle = {...vehicle};
      this.featuresDialog = true;
    },

    // Save features after editing
    saveFeatures() {
      this.$axios.put(`/api/vehicles/${this.selectedVehicle.id}/features`, {
        features: this.selectedVehicle.features,
      })
          .then(() => {
            Object.assign(
                this.vehiclesData.find((v) => v.id === this.selectedVehicle.id),
                this.selectedVehicle
            );
            this.featuresDialog = false;
          }).catch((error) => {
        console.error('Failed to save features:', error);
      });
    },

    // Add a new feature input
    addFeature() {
      this.selectedVehicle.features.push('');
    },

    // Remove a feature input by index
    removeFeature(index) {
      this.selectedVehicle.features.splice(index, 1);
    },
  },
};
</script>

<style scoped>
/* Optional custom styling */
</style>
