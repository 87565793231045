import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(Vuetify);

export default new Vuetify({
});
