export let EMPLOYEE_CASH_BACKS = "/:id/cash_backs";
export const EMPLOYEE_LIST = "/employee/list"
export const EMPLOYEE_STATS = "/employee/stats"
export const EVENT_LIST = "/events/list"
export const EVENT_DEPART_REVENU_REPORTS = "/events/:id/reports"
export const EVENT_EXPENSES = "/events/:id/expenses"
export const EVENT_DEPARTS = "/events/:id/departs"
export const EVENT_QR_CODES = "/events/:id/generate_qr_codes"
export const BUS_BOOKINGS = "/buses/:id/bookings"
export const EVENT_CURRENT_DEPARTS = "/events/departs"
export const POINTS_DEPART_LIST = "/point_departs/list"
