<template>
  <v-navigation-drawer
      v-model="drawer"
      app class="info"
  >

    <v-list v-if="isGranted('ROLE_EMPLOYEE')" >
        <v-col>
          <v-text-field v-model='searchValue' class="" @keyup="findCustomer()" label="Rechercher un client"  append-icon="mdi-magnify"></v-text-field>
        </v-col>

      <v-list-item
          v-for="(depart,i) in departsFigures"
          :key="i"
          link
      >
        <v-list-item-icon>
          <v-icon>mdi-car</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ depart.depart }} </v-list-item-title>
          <div v-for="(bus,index) in depart.buses" :key="index">
            <v-icon v-if="bus.closed">mdi-lock</v-icon> {{bus.name}}
            <template v-if="1<2 || isGranted('ROLE_OWNER') || bus.shouldShowSeatNumbers">
              <v-badge :content='bus.bookingsCount' inline dark color="error" @click="alert(bus.name)"></v-badge>
              <v-badge :content='bus.ticketsSoldCount' inline dark color="success"></v-badge>
              <v-badge :content='bus.bookedSeatsCount' inline dark color="indigo"></v-badge>
            </template>
            <template v-else>
              <v-icon dark color="error" v-if="bus.closed">mdi-lock</v-icon>
              <v-icon dark color="green darken-4" v-else>mdi-lock-open-variant</v-icon>
            </template>
          </div>

        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>

</template>

<script>
import {eventBus} from "@/main";

export default {
name: "AppNavDrawer",
  data() {
    return {
      departsFigures: [
           ],
      drawer: true,
      searchValue: null
    }
  },
  methods: {
  loadFigures(){
    this.$axios.request({
      shouldNotShowLoadingIndicator: true,
      method: 'get',
      url:"departs/bookings_counts",})
    .then(response =>{
      if (response.isSuccessful()){
        this.departsFigures = response.getData()
      }
    })
  },
    findCustomer() {
    eventBus.$emit("find_customer",this.searchValue)
    }
  },
  mounted() {
  setInterval(this.loadFigures, 30000)
    if (this.isLoggedIn) {
      this.loadFigures()
    }
  }
}
</script>

<style scoped>

</style>
