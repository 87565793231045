<template>
  <v-row>
    <v-col>
      <v-data-table :items="employees" :headers="headers" >
        <template v-slot:item.actions="{ item }">
          <v-btn title="Faire un retour caisse" icon color="indigo" @click="onCashBack(item)"><v-icon>mdi-reply</v-icon></v-btn>
          <v-btn icon color="info"><v-icon>mdi-pencil</v-icon></v-btn>
          <v-btn title="Suspendre" icon color="error"  @click="onBanEmployee(item)"><v-icon>mdi-cancel</v-icon></v-btn>
          <v-btn title="Autorisations" icon color="indigo" @click="openPrivilegesForm(item)"><v-icon>mdi-lock</v-icon></v-btn>
        </template>
        <template v-slot:item.ticketSaleAccount.balance="{item, value }">
          {{value}}<v-icon color="info" @click="showCashBacks(item)">mdi-eye</v-icon>

        </template>
      </v-data-table>
    </v-col>
    <v-dialog v-model="showPrivModal" class="col-xs-12 col-md-6 col-lg-6">
      <v-card v-if="showPrivModal">
        <v-card-title></v-card-title>
        <v-card-text>
          <component :is="privComponent" v-bind="privComponent" :key="renderKey"/>
        </v-card-text>
      </v-card>
      <v-card-actions></v-card-actions>
    </v-dialog>
  </v-row>
</template>

<script>
import EmployeePrivileges from "@/views/employee/EmployeePrivileges";
import {EMPLOYEE_CASH_BACKS} from "@/constants/routeConstants.ts";
import RebalanceTicketSaleAccount from "@/views/employee/RebalanceTicketSaleAccount";
export default {
  name: "EmployeeList",
  components: {EmployeePrivileges},
  data() {
    return {
      employees: [],
      headers:[
        {
          text:'Nom Complet',
          value: 'fullName'
        },{
          text:'Téléphone',
          value: 'phoneNumber'
        },
        {
          text:'Poste',
          value: 'jobTitle'
        },{
          text:'Solde',
          value: 'ticketSaleAccount.balance'
        },{
          text:'Action',
          value: 'actions'
        }
      ],
      showPrivModal: false,
      privComponent: null,
      renderKey: 0
    }
  },
  mounted() {
    this.$axios.get("employees")
        .then(response => {
          if (response.isSuccessful()) {
            this.employees = response.getData()
          }

        })
  },
  methods: {
    onUpdate(item) {
      console.log(item)
    },
    openPrivilegesForm(item) {

      this.showPrivModal = true
      this.privComponent = {
        is: EmployeePrivileges,
        employee: item

      }
    },
    onCashBack(item) {
      this.showPrivModal = true
      this.privComponent = {
        is: RebalanceTicketSaleAccount,
        employee: item

      }
    },
    onBanEmployee(item) {
      this.showAlertConfirm({title: "Bloquer cet  Employé?",
        confirmationMessage: "Voulez vous vraiment bloquer " + item.fullName + "?",
        okButton:"Confirmer", cancelButton: "Annuler"}, ()=>{
        let id = item.id;
        this.$axios.put("employees/:id".replace(":id", id), {disabled: true})
            .then(response => {
              if (response.isSuccessful()) {
                this.showSuccessAlert(item.fullName +" bloqué avec succès !")
              }
            }).catch(error => {
          let response = error.response
          if (response.isUnprocessableEntity()) {
            this.showErrorAlert(response.flattenValidationErrors)
          }
        })
      })
    },
    showCashBacks(item) {
      this.$router.push(EMPLOYEE_CASH_BACKS.replace(':id', item.ticketSaleAccount.id));
    }
  }
}
</script>

<style scoped>

</style>
