import * as userRoles from '@/constants/userRoles.ts'
export const user_login = {
    namespaced: true,
    state: {
        isLoggedIn: false,
        shouldChangePassword: false,
        user: {
        id:null,
        isAdmin: false,
        isSuperAdmin: false,
        isCEO: true,
        isFullEmployee: false,
        isAmbassador: false,
        canSellTicket: false,
        token: null,
        username:'',
        balance: null,
        roles: []
    }},
    actions: {
        saveUserCredentials({ commit }, data) {

                let token =  data.token
                let roles =  data.user.roles
                let username =  data.user.username
                let balance =  data.user.balance
                let id =  data.user.id
                let shouldChangePassword =  data.user.shouldChangePassword
                commit('mutateToken',token)
                commit('mutateRoles',roles)
                commit('mutateUsername',username)
                commit('mutateBalance',balance)
                commit('markAsAdminIfGranted')
                commit('markAsSuperAdminIfGranted')
                commit('mutateCanSellTicket')
                commit('mutateId', id)
                commit('mutateShouldChangePassword', shouldChangePassword)


        },
        loginUser({ commit }) {
            commit("markAsLoggedIn");
        },
        logoutUser({ commit }) {
            commit("markAsLoggedOut");
            commit("mutateShouldChangePassword", false);

        }, changePassword({ commit }, data) {
            commit("mutateShouldChangePassword",true);
            commit("mutateId",data.user.id);
            commit("mutateToken",data.token);
        }

    },
    mutations: {
        markAsLoggedIn(state) {
            state.isLoggedIn = true;
        },
        markAsAdminIfGranted(state) {
            state.user.isAdmin = state.user.roles.indexOf(userRoles.ROLE_ADMIN) >= 0;
        },  markAsSuperAdminIfGranted(state) {
            state.user.isSuperAdmin = state.user.roles.indexOf(userRoles.ROLE_SUPER_ADMIN) >= 0;
        } ,mutateCanSellTicket(state) {
            state.user.canSellTicket = state.user.roles.indexOf(userRoles.ROLE_EMPLOYEE) >= 0;
        },
        markAsLoggedOut(state) {
            state.isLoggedIn = false;
        },
        mutateToken(state, token) {
            state.user.token = token;
        },mutateUsername(state, username) {
            state.user.username = username;
        },mutateBalance(state, balance) {
            state.user.balance = balance;
        },mutateRoles(state, roles) {
            state.user.roles = roles;
        },mutateId(state, id) {
            state.user.id = id;
        }, mutateShouldChangePassword(state, bool) {
            state.shouldChangePassword = bool;
        },

    },
    getters:{
        isUserAnAdmin(){
            return this.state.user.roles.indexOf(userRoles.ROLE_ADMIN) >= 0 || this.state.user.roles.indexOf(userRoles.ROLE_SUPER_ADMIN) >= 0
        }
    }
};

