<template>
  <div>
    <v-card>
      <v-card-title class="text-center">Les sièges du bus {{bus.fullName}}</v-card-title>
      <v-card-text>
        <v-row>
          <!--  col for left-edge-->
          <v-col cols="2">
            <v-list>
              <v-list-item v-for="(seat, index) in leftEdgePositionSeats" :key="index">
                <v-list-item-content><span><SeatNumberRenderer @on-seat-click="onBusSeatSelected(seat)"
                                                               :seat="seat"></SeatNumberRenderer> </span>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col><!--  col for left-middle-->
          <v-col cols="2">
            <v-list>
              <v-list-item v-for="(seat, index) in leftMiddlePositionSeats" :key="index">
                <v-list-item-content><span><SeatNumberRenderer :seat="seat" @on-seat-click="onBusSeatSelected(seat)"></SeatNumberRenderer> </span>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <!--  col for center-->
          <v-col cols="2">
            <v-list>
              <v-list-item v-for="(seat, index) in centerPositionSeats" :key="index">
                <v-list-item-content><span><SeatNumberRenderer @on-seat-click="onBusSeatSelected(seat)" :seat="seat"></SeatNumberRenderer> </span>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="2">
            <v-list>
              <v-list-item v-for="(seat, index) in rightMiddlePositionSeats" :key="index">
                <v-list-item-content><span><SeatNumberRenderer @on-seat-click="onBusSeatSelected(seat)" :seat="seat"></SeatNumberRenderer> </span>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <!--  col for right-edge-->
          <v-col cols="2">
            <v-list>
              <v-list-item v-for="(seat, index) in rightEdgePositionSeats" :key="index">
                <v-list-item-content><span><SeatNumberRenderer @on-seat-click="onBusSeatSelected(seat)" :seat="seat"></SeatNumberRenderer> </span>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>

        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="newBookingDialog" class="col-md-6 col-xs-12">
      <v-card>
        <v-card-text>
          <component :is='newBookingComponent' v-bind='newBookingComponent' :key="renderKey"></component>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SeatNumberRenderer from "@/views/bus/SeatNumberRenderer";
import NewBooking from "@/views/booking/NewBooking";
export default {
  name: "BusSeats",
  components: {SeatNumberRenderer},
  props: {
    bus:{
      type: Object,
      required: true
    },
    depart:{
      type: Object,
      required: true
    },
  },
  data() {
    return {
      seats: [],
      leftEdgePositionSeats:[],
      leftMiddlePositionSeats:[],
      rightMiddlePositionSeats:[],
      rightEdgePositionSeats:[],
      centerPositionSeats: [],
      newBookingDialog: false,
      newBookingComponent: null,
      renderKey: 0
    }
  },
  mounted() {

this.$axios.get("buses/:id/seats".replace(":id", this.bus.id))
     .then(response => {
       if (response.isSuccessful()) {
         this.seats = response.getData()
         this.arrangeSeats();
       }
     })
  },
  methods: {
    arrangeSeats(){
      let letEdgePositionSeatNumbers = [1,5,9,13,17,21,25,29,33,37,41,45,49,53]
      let letMiddlePositionSeatNumbers = [2,6,10,14,18,22,26,30,34,38,42,46,50,54,]
      let rightMiddlePositionSeats = [3,7,11,15,19,23,27,31,35,39,43,47,51,56];
      let rightEdgePositionSeats = [4,8,12,16,20,24,28,32,36,40,44,48,52,57];
      let centerPositionSeats = [70, 69,68, 67,66,65,64,63,62,61,60,59,58,55]

      for (let i = 0; i < this.seats.length ; i++) {
        console.log(this.seats[i].number)
        if(letEdgePositionSeatNumbers.indexOf(this.seats[i].number) !== -1)
        {
          this.seats[i].position = 1
        }else if (letMiddlePositionSeatNumbers.indexOf(this.seats[i].number) !== -1) {
          this.seats[i].position = 2
        }else if (rightMiddlePositionSeats.indexOf(this.seats[i].number) !== -1) {
          this.seats[i].position = 3
        }else if (rightEdgePositionSeats.indexOf(this.seats[i].number) !== -1) {
          this.seats[i].position = 4
        }else if (centerPositionSeats.indexOf(this.seats[i].number) !== -1) {
          this.seats[i].position = 5
        }else {
          this.seats[i].position = -1;
        }

      }
      console.log(this.seats)
      this.leftEdgePositionSeats = this.seats.filter((item) => { return item.position === 1;} )

      this.leftMiddlePositionSeats =  this.seats.filter((item) => { return item.position === 2;})
      this.rightMiddlePositionSeats =  this.seats.filter((item) => { return item.position === 3;})
      this.rightEdgePositionSeats =  this.seats.filter((item) => { return item.position === 4;})
      this.centerPositionSeats =  this.seats.filter((item) => { return item.position === 5;})
      this.centerPositionSeats.sort(function (a,b){ return b.number - a.number})
      if (this.seats.length === 69) {
        this.centerPositionSeats.unshift({number: 'Apprenti', booked: true, available: false})

      }else if (this.seats.length === 70) {
        this.centerPositionSeats[0].booked=true
        this.centerPositionSeats[0].number='Apprenti'
        this.centerPositionSeats[1].number='Apprenti'
        this.centerPositionSeats[1].booked=true
      }

    },
    onBusSeatSelected(seat) {
      if (this.bus.allowsSeatSelection) {
        this.renderKey++
        this.newBookingDialog = true
        this.newBookingComponent = {
          is: NewBooking,
          bus: this.bus,
          depart: this.depart,
          seat: seat
        }
      }else{
        this.showErrorAlert("Choix de numéro de place désactivé sur ce bus")
      }
    }
  }
}
</script>


<style scoped>

</style>
