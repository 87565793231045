<template>
  <v-row>
    <v-col class="ma-lg"><h1>Etat des caisses</h1>
      <v-data-table
          sortBy="total"
          :headers="headers"
          :items-per-page="70"
          locale="fr-FR"
          loading-text="Chargement des données..."
          :items="ticketSales">
        <template v-slot:item.total="{ item }">
          {{ parseInt(item.total).toLocaleString() }}
        </template>


      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "TicketSalesByPaymentMethod",
  data() {
    return {
      ticketSales : [],
      headers: [

        {
          text: "Methode Paiement",
          value: "paymentMethod"
        },{
          text: "Total",
          value: "total"
        }
      ],    }
  },
  mounted() {

    this.$axios.get("finance/caisses")
        .then(response=>{
          if (response.isSuccessful()){
            // this.ticketSales = [
            //     {"total":"75240","paymentMethod":"cash"},
            //   {"total":"43560","paymentMethod":"om"},
            //   {"total":"170280","paymentMethod":"WAVE"}]

            this.ticketSales = response.getData()
          }
        }).catch(error=>{
      this.showErrorAlert("Impossible de charger les données; voir console pour les erreurs !")
      console.log(error)
    })
  }
}
</script>

<style scoped>

</style>
